import type { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { formatUnits, type Hex, hexToBigInt, isHex, parseUnits } from 'viem'

export function amount_to_tokens(
  amount: bigint | undefined | Hex,
  decimals: number,
): number {
  if (amount == null) return 0

  if (isHex(amount)) {
    amount = hexToBigInt(amount)
  }

  return Number(formatUnits(amount, decimals))
}

export function tokens_to_amount(
  tokens: number | string,
  decimals: number,
): bigint {
  return parseUnits(String(tokens), decimals)
}

export function findCurrencyAmount({
  symbol,
  pair,
  not = false,
}: {
  symbol: string
  pair: [CurrencyAmount<Token>, CurrencyAmount<Token>]
  not?: boolean
}): CurrencyAmount<Token> {
  if (not) {
    return pair[0].currency.symbol === symbol ? pair[1] : pair[0]
  }
  return pair[0].currency.symbol === symbol ? pair[0] : pair[1]
}

export function to_decimal_scale(decimal: number): bigint {
  return BigInt(Math.pow(10, decimal))
}
