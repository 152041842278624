import { useMemo } from 'react'

import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import {
  type QueryClient,
  type QueryKey,
  skipToken,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { Alchemy, AssetTransfersCategory, Network } from 'alchemy-sdk'
import dayjs from 'dayjs'
import * as _ from 'lodash-es'
import { getAbiItem, type Hex, hexToBigInt, numberToHex } from 'viem'
import { useAccount, usePublicClient } from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import type { TokenRenderProps } from '../../components/TokenRender'
import { merge_queries } from '../../helpers/query_helpers'
import { fissionFundMinterAbi } from '../blockchain/abis/fissionFundMinterAbi'
import { fissionVaultAbi } from '../blockchain/abis/fissionVaultAbi'
import { uniswapV3PoolAbi } from '../blockchain/abis/UniswapV3Pool'
import { useGetDecimals } from '../blockchain/useGetDecimals'
import { useGetWalletBalance } from '../blockchain/useGetWalletBalance'
import { useListRewardWalletAddresses } from '../crypto_contracts'
import {
  useGetPoolAddress,
  useGetPoolConfig,
  useGetPositions,
  useGetPriceHistory,
  useGetTokenPrice,
} from '../fission_dex'
import { amount_to_tokens, to_decimal_scale } from '../helpers/block_helpers'
import { useProductInfo, useTokenInfo } from '../products'
import type { TokenSymbol } from '../products/products_queries'
import { useGetVaultHistory, useGetVaultLatestData } from '../vaults'

import { useGetBlockPrices } from './portfolio_price_queries'

const config = {
  apiKey: import.meta.env.VITE_ALCHEMY_KEY,
  network: Network.ETH_SEPOLIA,
}
const alchemy = new Alchemy(config)

export const keys = createQueryKeyStore({
  portfolio: {
    transfers: null,
    events_fund_minted: null,
    events_vault_minted: null,
    events_vault_redeemed: null,
    events_dex_swap: null,
    block_prices: (args: { symbol: TokenSymbol }) => [args],
  },
})

export type PortfolioTokenSymbol = Extract<
  TokenSymbol,
  'FISN' | 'TECH' | 'TVLT'
>

export type TransactionRecord = {
  type:
    | 'transfer'
    | 'fund minted'
    | 'vault minted'
    | 'vault redeemed'
    | 'swap'
    | 'reward'
  id: string
  tx: Hex
  block_number: bigint
  symbol: PortfolioTokenSymbol
  timestamp: number | null
  change: bigint
  balance_change_usdc: bigint | null
  xirr_change: bigint | null
  from_address: Hex | null
}

export type LedgerRecord = {
  type: TransactionRecord['type']
  id: string
  tx: Hex
  symbol: PortfolioTokenSymbol
  timestamp: number
  change: bigint
  change_value: number
  balance_change_usdc_value: number
  xirr_change_value: number
}

const portfolio_staleTime = 1000 * 60 * 10 // 10 minutes

export function useGetSymbolToDecimal() {
  const { data: fisn_token } = useTokenInfo({ symbol: 'FISN' })
  const { data: tech_token } = useTokenInfo({ symbol: 'TECH' })
  const { data: tvlt_token } = useTokenInfo({ symbol: 'TVLT' })
  const { data: fisn_decimals } = useGetDecimals(fisn_token?.token_address)
  const { data: tech_decimals } = useGetDecimals(tech_token?.token_address)
  const { data: tvlt_decimals } = useGetDecimals(tvlt_token?.token_address)

  return useMemo(() => {
    const enabled =
      fisn_decimals != null && tech_decimals != null && tvlt_decimals != null
    if (!enabled) return null

    return {
      FISN: fisn_decimals,
      TECH: tech_decimals,
      TVLT: tvlt_decimals,
      USDC: 6,
    } satisfies Record<PortfolioTokenSymbol | 'USDC', number>
  }, [fisn_decimals, tech_decimals, tvlt_decimals])
}

export function useGetAddressToSymbol() {
  const fisn_address = useTokenInfo({ symbol: 'FISN' }).data?.token_address
  const tech_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address
  const tvlt_address = useTokenInfo({ symbol: 'TVLT' }).data?.token_address

  return useMemo(() => {
    const enabled =
      fisn_address != null && tech_address != null && tvlt_address != null
    if (!enabled) return null

    return {
      [fisn_address.toLowerCase() as CryptoAddress]: 'FISN',
      [tech_address.toLowerCase() as CryptoAddress]: 'TECH',
      [tvlt_address.toLowerCase() as CryptoAddress]: 'TVLT',
    } satisfies Record<CryptoAddress, PortfolioTokenSymbol>
  }, [fisn_address, tech_address, tvlt_address])
}

function get_last_stuff({
  queryKey,
  queryClient,
}: {
  queryKey: QueryKey
  queryClient: QueryClient
}) {
  let last_records = _.sortBy(
    queryClient.getQueryData<TransactionRecord[]>(queryKey) ?? [],
    'block_number',
  )

  let last_block_number = _.last(last_records)?.block_number

  if (last_block_number != null) {
    // go back 3 blocks to ensure safeness
    last_block_number -= 3n
    // removed unsafe transfers from the last 3 blocks
    last_records = _.filter(
      last_records,
      // ts is messing up here - last_block_number has to be bigint after the math above
      (r) => r.block_number < (last_block_number as bigint),
    )
  } else {
    last_block_number = 0n
  }

  return {
    last_records,
    fromBlock: last_block_number,
  }
}

export function useGetTransferHistory() {
  const { address } = useAccount()
  const address_to_symbol = useGetAddressToSymbol()
  const queryClient = useQueryClient()

  const enabled = address != null && address_to_symbol != null
  const queryKey = keys.portfolio.transfers.queryKey

  return useQuery<TransactionRecord[]>({
    queryKey,
    staleTime: portfolio_staleTime,
    gcTime: Infinity,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const { last_records, fromBlock } = get_last_stuff({
            queryKey,
            queryClient,
          })

          const to_data_p = alchemy.core.getAssetTransfers({
            toAddress: address,
            category: [AssetTransfersCategory.ERC20],
            contractAddresses: Object.keys(address_to_symbol),
            withMetadata: true,
            fromBlock: numberToHex(fromBlock),
            toBlock: 'latest',
          })
          const from_data_p = alchemy.core.getAssetTransfers({
            fromAddress: address,
            category: [AssetTransfersCategory.ERC20],
            contractAddresses: Object.keys(address_to_symbol),
            withMetadata: true,
            fromBlock: numberToHex(fromBlock),
            toBlock: 'latest',
          })

          const results = await Promise.all([to_data_p, from_data_p])

          return _.sortBy(
            last_records.concat(
              results
                .flatMap(({ transfers }) => transfers)
                .map((transfer) => {
                  const sign =
                    transfer.to!.toLowerCase() == address.toLowerCase()
                      ? 1n
                      : -1n
                  const change = hexToBigInt(transfer.rawContract.value! as Hex)

                  const entry: TransactionRecord = {
                    type: 'transfer',
                    id: transfer.uniqueId,
                    tx: transfer.hash as Hex,
                    symbol:
                      address_to_symbol[
                        transfer.rawContract.address!.toLowerCase() as Hex
                      ],
                    timestamp: dayjs(
                      transfer.metadata.blockTimestamp,
                    ).valueOf(),
                    block_number: hexToBigInt(transfer.blockNum as Hex),
                    change: sign * change,
                    balance_change_usdc: null,
                    xirr_change: null,
                    from_address:
                      transfer.from.toLowerCase() != address.toLowerCase()
                        ? (transfer.from as Hex)
                        : null,
                  }
                  return entry
                }),
            ),
            'block_number',
          )
        },
  })
}

const event_fund_minted = getAbiItem({
  abi: fissionFundMinterAbi,
  name: 'Minted',
})

const event_vault_minted = getAbiItem({
  abi: fissionVaultAbi,
  name: 'Minted',
})

const event_vault_redeemed = getAbiItem({
  abi: fissionVaultAbi,
  name: 'Redeemed',
})

const event_dex_swap = getAbiItem({
  abi: uniswapV3PoolAbi,
  name: 'Swap',
})

function useGetEventFundMinted() {
  const { address } = useAccount()
  const symbol = 'TECH'
  const tech_data = useProductInfo({ symbol }).data
  const client = usePublicClient({ chainId: active_chain.id })
  const queryClient = useQueryClient()

  const enabled = address != null && tech_data != null && client != null

  const queryKey = keys.portfolio.events_fund_minted.queryKey

  return useQuery<TransactionRecord[]>({
    queryKey,
    staleTime: portfolio_staleTime,
    gcTime: Infinity,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const { last_records, fromBlock } = get_last_stuff({
            queryKey,
            queryClient,
          })
          const logs = await client.getLogs({
            address: tech_data.minter_history.map((h) => h.address),
            event: event_fund_minted,
            fromBlock,
            toBlock: 'latest',
            args: {
              recipient: address,
            },
            strict: true,
          })
          return last_records.concat(
            logs.map((log) => {
              return {
                type: 'fund minted',
                id: log.transactionHash + '-' + log.transactionIndex,
                tx: log.transactionHash,
                symbol,
                timestamp: null,
                block_number: log.blockNumber,
                change: log.args.mint_amount,
                balance_change_usdc: log.args.usdc_amount,
                xirr_change: -log.args.usdc_amount,
                from_address: null,
              } satisfies TransactionRecord
            }),
          )
        },
  })
}

function useGetEventVaultMinted() {
  const { address } = useAccount()
  const symbol = 'TVLT'
  const vault_data = useProductInfo({ symbol }).data
  const client = usePublicClient({ chainId: active_chain.id })
  const symbol_to_decimal = useGetSymbolToDecimal()
  const queryClient = useQueryClient()

  const enabled =
    address != null &&
    vault_data != null &&
    client != null &&
    symbol_to_decimal != null

  const queryKey = keys.portfolio.events_vault_minted.queryKey

  return useQuery<TransactionRecord[]>({
    queryKey,
    staleTime: portfolio_staleTime,
    gcTime: Infinity,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const { last_records, fromBlock } = get_last_stuff({
            queryKey,
            queryClient,
          })
          const decimal_scale = to_decimal_scale(symbol_to_decimal[symbol])

          const logs = await client.getLogs({
            address: vault_data.minter_history.map((h) => h.address),
            event: event_vault_minted,
            fromBlock,
            toBlock: 'latest',
            args: {
              recipient: address,
            },
            strict: true,
          })

          return last_records.concat(
            logs.flatMap((log) => {
              const balance_change_usdc =
                (log.args.vault_price * log.args.mint_amount) / decimal_scale
              return [
                {
                  type: 'vault minted',
                  id: log.transactionHash + '-' + log.transactionIndex + '-out',
                  tx: log.transactionHash,
                  symbol,
                  timestamp: null,
                  block_number: log.blockNumber,
                  change: log.args.mint_amount,
                  balance_change_usdc,
                  xirr_change: -balance_change_usdc,
                  from_address: null,
                },
                {
                  type: 'vault minted',
                  id: log.transactionHash + '-' + log.transactionIndex + '-in',
                  tx: log.transactionHash,
                  symbol: 'TECH',
                  timestamp: null,
                  block_number: log.blockNumber,
                  change: -log.args.tech_amount,
                  balance_change_usdc:
                    log.args.usdc_amount - balance_change_usdc,
                  xirr_change: -(log.args.usdc_amount - balance_change_usdc),
                  from_address: null,
                },
              ] satisfies TransactionRecord[]
            }),
          )
        },
  })
}

function useGetEventVaultRedeemed() {
  const { address } = useAccount()
  const symbol = 'TVLT'
  const vault_data = useProductInfo({ symbol }).data
  const client = usePublicClient({ chainId: active_chain.id })
  const queryClient = useQueryClient()
  const symbol_to_decimal = useGetSymbolToDecimal()

  const enabled =
    address != null &&
    vault_data != null &&
    client != null &&
    symbol_to_decimal != null
  const queryKey = keys.portfolio.events_vault_redeemed.queryKey

  return useQuery<TransactionRecord[]>({
    queryKey: keys.portfolio.events_vault_redeemed.queryKey,
    staleTime: portfolio_staleTime,
    gcTime: Infinity,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const { last_records, fromBlock } = get_last_stuff({
            queryKey,
            queryClient,
          })
          const decimal_scale = to_decimal_scale(symbol_to_decimal[symbol])

          const logs = await client.getLogs({
            address: vault_data.minter_history.map((h) => h.address),
            event: event_vault_redeemed,
            fromBlock,
            toBlock: 'latest',
            args: {
              recipient: address,
            },
            strict: true,
          })
          return last_records.concat(
            logs.flatMap((log) => {
              const balance_change_usdc =
                -(log.args.vault_price * log.args.burn_amount) / decimal_scale
              return [
                {
                  type: 'vault redeemed',
                  id: log.transactionHash + '-' + log.transactionIndex + '-in',
                  tx: log.transactionHash,
                  symbol,
                  timestamp: null,
                  block_number: log.blockNumber,
                  change: -log.args.burn_amount,
                  balance_change_usdc,
                  xirr_change: -balance_change_usdc,
                  from_address: null,
                },
                {
                  type: 'vault redeemed',
                  id: log.transactionHash + '-' + log.transactionIndex + '-out',
                  tx: log.transactionHash,
                  symbol: 'TECH',
                  timestamp: null,
                  block_number: log.blockNumber,
                  change: log.args.tech_amount,
                  balance_change_usdc:
                    -balance_change_usdc - log.args.usdc_amount,
                  xirr_change: -(-balance_change_usdc - log.args.usdc_amount),
                  from_address: null,
                },
              ] satisfies TransactionRecord[]
            }),
          )
        },
  })
}

function useGetEventDexSwap() {
  const { address } = useAccount()
  const symbol = 'TECH'
  const { data: pool_address } = useGetPoolAddress(symbol)
  const { data: pool } = useGetPoolConfig(symbol)
  const client = usePublicClient({ chainId: active_chain.id })
  const queryClient = useQueryClient()

  const enabled =
    address != null && pool_address != null && client != null && pool != null
  const queryKey = keys.portfolio.events_dex_swap.queryKey

  return useQuery<TransactionRecord[]>({
    queryKey: keys.portfolio.events_dex_swap.queryKey,
    staleTime: portfolio_staleTime,
    gcTime: Infinity,
    queryFn: !enabled
      ? skipToken
      : // eslint-disable-next-line max-lines-per-function
        async () => {
          const { last_records, fromBlock } = get_last_stuff({
            queryKey,
            queryClient,
          })
          const logs = await client.getLogs({
            address: [pool_address],
            event: event_dex_swap,
            fromBlock,
            toBlock: 'latest',
            args: {
              recipient: address,
            },
            strict: true,
          })
          return last_records.concat(
            logs.map((log) => {
              let change = -log.args.amount0
              let balance_change_usdc = log.args.amount1
              if (pool.token0.symbol === 'USDC') {
                change = -log.args.amount1
                balance_change_usdc = log.args.amount0
              }

              return {
                type: 'swap',
                id: log.transactionHash + '-' + log.transactionIndex,
                tx: log.transactionHash,
                symbol,
                timestamp: null,
                block_number: log.blockNumber,
                change,
                balance_change_usdc,
                xirr_change: -balance_change_usdc,
                from_address: null,
              }
            }),
          )
        },
  })
}

function clean_gov({
  transfer_data,
  reward_wallets,
}: {
  transfer_data: TransactionRecord[]
  reward_wallets: Hex[]
}): TransactionRecord[] {
  return transfer_data.map((record) => {
    if (
      record.symbol == 'FISN' &&
      reward_wallets.includes(record.from_address!)
    ) {
      return {
        ...record,
        type: 'reward',
        xirr_change: 0n,
      } satisfies TransactionRecord
    }
    return record
  })
}

function clean_transfer_from_event({
  transfer_data,
  event_data,
}: {
  transfer_data: TransactionRecord[]
  event_data: TransactionRecord[]
}): TransactionRecord[] {
  return transfer_data.map((record) => {
    const matching = _.find(event_data, {
      tx: record.tx,
      change: record.change,
      symbol: record.symbol,
    })
    if (matching) {
      return {
        ...matching,
        timestamp: record.timestamp,
      }
    }

    return record
  })
}

function clean_transfer_with_block_prices({
  cleaned_data,
  block_prices,
  symbol_to_decimal,
}: {
  cleaned_data: TransactionRecord[]
  block_prices: Record<string, Record<string, bigint>>
  symbol_to_decimal: Record<PortfolioTokenSymbol, number>
}) {
  if (symbol_to_decimal == null) return []

  return cleaned_data.map((record) => {
    if (record.xirr_change != null && record.balance_change_usdc != null)
      return record

    const price = block_prices[record.symbol][record.block_number.toString()]
    const decimal_scale = to_decimal_scale(symbol_to_decimal[record.symbol])

    if (price != null) {
      const balance_change_usdc = (record.change * price) / decimal_scale
      if (record.xirr_change == null) {
        return {
          ...record,
          balance_change_usdc,
          xirr_change: -balance_change_usdc,
        }
      }
      // e.g., rewards
      return {
        ...record,
        balance_change_usdc,
      }
    }
    return record
  })
}

// eslint-disable-next-line max-lines-per-function
export function useGetPortfolioLedger() {
  const transfer_query = useGetTransferHistory()
  const reward_wallet_query = useListRewardWalletAddresses()
  const fund_minted_query = useGetEventFundMinted()
  const vault_minted_query = useGetEventVaultMinted()
  const vault_redeemed_query = useGetEventVaultRedeemed()
  const swap_query = useGetEventDexSwap()
  const symbol_to_decimal = useGetSymbolToDecimal()
  const { isConnected } = useAccount()

  // eslint-disable-next-line complexity
  const cleaned_data = useMemo(() => {
    const enabled =
      transfer_query.data != null &&
      reward_wallet_query.data != null &&
      fund_minted_query.data != null &&
      vault_minted_query.data != null &&
      vault_redeemed_query.data != null &&
      swap_query.data != null

    if (!enabled) return null

    let transfer_data = transfer_query.data
    transfer_data = clean_gov({
      transfer_data,
      reward_wallets: reward_wallet_query.data,
    })
    transfer_data = clean_transfer_from_event({
      transfer_data,
      event_data: fund_minted_query.data,
    })
    transfer_data = clean_transfer_from_event({
      transfer_data,
      event_data: vault_minted_query.data,
    })
    transfer_data = clean_transfer_from_event({
      transfer_data,
      event_data: vault_redeemed_query.data,
    })
    transfer_data = clean_transfer_from_event({
      transfer_data,
      event_data: swap_query.data,
    })

    return _.sortBy(transfer_data, 'timestamp').reverse()
  }, [
    fund_minted_query.data,
    reward_wallet_query.data,
    swap_query.data,
    transfer_query.data,
    vault_minted_query.data,
    vault_redeemed_query.data,
  ])

  const tech_block_prices_query = useGetBlockPrices({
    symbol: 'TECH',
    cleaned_data,
  })
  const tvlt_block_prices_query = useGetBlockPrices({
    symbol: 'TVLT',
    cleaned_data,
  })
  const fisn_block_prices_query = useGetBlockPrices({
    symbol: 'FISN',
    cleaned_data,
  })

  const ledger: LedgerRecord[] = useMemo(() => {
    const enabled =
      tech_block_prices_query.data != null &&
      tvlt_block_prices_query.data != null &&
      fisn_block_prices_query.data != null &&
      cleaned_data != null &&
      symbol_to_decimal != null

    if (!enabled) return []

    const clean = clean_transfer_with_block_prices({
      cleaned_data,
      symbol_to_decimal,
      block_prices: {
        TECH: tech_block_prices_query.data,
        TVLT: tvlt_block_prices_query.data,
        FISN: fisn_block_prices_query.data,
      },
    })
    const results: LedgerRecord[] = []
    clean.forEach((record) => {
      if (
        record.timestamp == null ||
        record.balance_change_usdc == null ||
        record.xirr_change == null
      ) {
        return
      }

      results.push({
        type: record.type,
        id: record.id,
        tx: record.tx,
        symbol: record.symbol,
        timestamp: record.timestamp,
        change: record.change,
        change_value: amount_to_tokens(
          record.change,
          symbol_to_decimal[record.symbol],
        ),
        balance_change_usdc_value: amount_to_tokens(
          record.balance_change_usdc,
          symbol_to_decimal['USDC'],
        ),
        xirr_change_value: amount_to_tokens(
          record.xirr_change,
          symbol_to_decimal['USDC'],
        ),
      })
    })
    return results
  }, [
    cleaned_data,
    fisn_block_prices_query.data,
    symbol_to_decimal,
    tech_block_prices_query.data,
    tvlt_block_prices_query.data,
  ])

  const queries = [
    transfer_query,
    reward_wallet_query,
    fund_minted_query,
    vault_minted_query,
    vault_redeemed_query,
    swap_query,
    tech_block_prices_query,
    tvlt_block_prices_query,
    fisn_block_prices_query,
  ]

  const progress = queries.reduce(
    (acc, curr) => acc + (curr.isSuccess ? 1 : 0),
    0,
  )

  return {
    ...merge_queries(queries),
    progress,
    total: queries.length,
    ledger,
    isConnected,
  }
}

type PortfolioStats = Record<
  PortfolioTokenSymbol,
  {
    average_paid: number
    total_paid: number
  }
>

function calc_stats({
  symbol,
  records,
}: {
  symbol: PortfolioTokenSymbol
  records: LedgerRecord[]
}): PortfolioStats[PortfolioTokenSymbol] {
  let total_paid = 0
  let total_gained = 0

  const buy_records: LedgerRecord[] = _.sortBy(
    records.filter(
      (record) =>
        record.symbol == symbol &&
        record.xirr_change_value != null &&
        record.change_value > 0,
    ),
    'block_number',
  )
  const sell_records: LedgerRecord[] = records.filter(
    (record) =>
      record.symbol == symbol &&
      record.xirr_change_value != null &&
      record.change_value < 0,
  )

  sell_records.forEach((record) => {
    let remove_amount = record.change * -1n

    // remove matching buying records
    while (remove_amount != 0n && buy_records.length > 0) {
      if (buy_records[0].change <= remove_amount) {
        remove_amount -= buy_records[0].change
        buy_records.shift() // remove the buy record
      } else {
        const new_change = buy_records[0].change - remove_amount
        const removed_ratio = Number(new_change) / Number(buy_records[0].change)

        const change_record: LedgerRecord = {
          ...buy_records[0],
          change: buy_records[0].change - remove_amount,
          change_value: removed_ratio * buy_records[0].change_value,
          xirr_change_value: removed_ratio * buy_records[0].xirr_change_value,
        }
        buy_records[0] = change_record
        remove_amount = 0n
      }
    }
  })

  buy_records.forEach((record) => {
    if (record.symbol != symbol) return

    total_paid += -1 * record.xirr_change_value // xirr is opposite of balance
    total_gained += record.change_value
  })

  return {
    average_paid:
      total_paid == 0 ? 0 : Number(total_paid) / Number(total_gained),
    total_paid,
  }
}

const tokens: PortfolioTokenSymbol[] = ['FISN', 'TECH', 'TVLT']
export function useGetPortfolioTableStats(): PortfolioStats {
  const ledger_query = useGetPortfolioLedger()

  return useMemo(() => {
    return tokens.reduce((acc, symbol) => {
      acc[symbol] = calc_stats({ symbol, records: ledger_query.ledger })
      return acc
    }, {} as PortfolioStats)
  }, [ledger_query.ledger])
}

export type BalanceRecord = {
  day: number
  balance_usdc_value: number
  components: Record<
    PortfolioTokenSymbol,
    {
      balance_tokens: number
      balance_usdc_value: number
    }
  >
}

// eslint-disable-next-line complexity, max-lines-per-function
export function useGetPortfolioHistory() {
  const ledger_query = useGetPortfolioLedger()
  const { data: tvlt_token } = useProductInfo({ symbol: 'TVLT' })
  const { data: tvlt_prices } = useGetVaultHistory({ id: tvlt_token?.id })
  const { data: tech_prices } = useGetPriceHistory({
    symbol: 'TECH',
    period_type: 'daily',
  })

  // eslint-disable-next-line max-lines-per-function, complexity
  return useMemo(() => {
    const enabled =
      !ledger_query.isLoading &&
      tvlt_prices.length > 0 &&
      tech_prices.length > 0 &&
      ledger_query.ledger.length > 0

    if (!enabled) return []
    const history = _.sortBy(ledger_query.ledger, 'timestamp')
    const sorted_tvlt_prices = _.sortBy(tvlt_prices, 'date_s')
    const sorted_tech_prices = _.sortBy(tech_prices, 'date')

    const get_price = (symbol: PortfolioTokenSymbol, date: number) => {
      let token_price = 0
      date = dayjs(date).endOf('day').valueOf()
      if (symbol === 'FISN') {
        token_price = 0.05
      } else if (symbol === 'TECH') {
        token_price =
          _.findLast(sorted_tech_prices, (p) => p.date <= date)
            ?.token_usdc_price ?? 0
      } else if (symbol === 'TVLT') {
        token_price = Number(
          _.findLast(sorted_tvlt_prices, (p) => Number(p.date_s) * 1000 <= date)
            ?.price_per_token ?? 0,
        )
      }
      return token_price
    }

    const combined_history: BalanceRecord[] = []

    let next_day = dayjs(history[0].timestamp).startOf('day')
    const today_end = dayjs().endOf('day')

    let last_record: BalanceRecord = {
      day: 0,
      balance_usdc_value: 0,
      components: tokens.reduce(
        (acc, symbol) => {
          acc[symbol] = {
            balance_tokens: 0,
            balance_usdc_value: 0,
          }
          return acc
        },
        {} as BalanceRecord['components'],
      ),
    }

    while (next_day.isBefore(today_end, 'day')) {
      const record: BalanceRecord = {
        day: next_day.valueOf(),
        balance_usdc_value: 0,
        components: _.cloneDeep(last_record.components),
      }

      tokens.forEach((symbol) => {
        const price = get_price(symbol, record.day)
        // update current
        record.components[symbol].balance_usdc_value =
          record.components[symbol].balance_tokens * price

        const entries = _.filter(
          history,
          (e) =>
            e.symbol == symbol &&
            dayjs(e.timestamp).isSame(dayjs(record.day), 'day'),
        )
        const change_value = _.sumBy(entries, (r) => r.change_value)
        const balance_change_usdc_value = _.sumBy(
          entries,
          (r) => r.balance_change_usdc_value,
        )
        record.components[symbol].balance_tokens += change_value
        record.components[symbol].balance_usdc_value +=
          balance_change_usdc_value
        // add to overall sum
        record.balance_usdc_value +=
          record.components[symbol].balance_usdc_value
      })

      combined_history.push(record)

      next_day = next_day.add(1, 'day')
      last_record = record
    }

    return combined_history
  }, [ledger_query.isLoading, ledger_query.ledger, tech_prices, tvlt_prices])
}

type PortfolioEntry = TokenRenderProps & {
  symbol: PortfolioTokenSymbol
  tokens: number
  usdc_value: number
  price: number
  average_paid: number
  gain_usdc: number
  gain_percent: number | null
}

function calc_prices({
  balance,
  price_per_token,
  total_paid,
}: {
  balance: number
  price_per_token: string | number
  total_paid: number
}) {
  const price = Number(price_per_token)
  const usdc_value = balance * price
  const gain_usdc = usdc_value - total_paid
  const gain_percent = total_paid <= 0 ? null : (gain_usdc / total_paid) * 100

  return {
    tokens: balance,
    price,
    usdc_value,
    gain_usdc,
    gain_percent,
  }
}

// eslint-disable-next-line max-lines-per-function
export function useListPortfolio() {
  const { address, isConnected } = useAccount()
  const tvlt_query = useProductInfo({ symbol: 'TVLT' })
  const tech_query = useProductInfo({ symbol: 'TECH' })
  const positions_query = useGetPositions({ wallet_address: address })

  const tech_price_result = useGetTokenPrice({ symbol: 'TECH' })
  const fisn_price_result = useGetTokenPrice({ symbol: 'FISN' })
  const vault_price_result = useGetVaultLatestData({ id: tvlt_query.data?.id })

  const wallet_tech = useGetWalletBalance('TECH')
  const wallet_tvlt = useGetWalletBalance('TVLT')
  const wallet_fisn = useGetWalletBalance('FISN')

  const stats = useGetPortfolioTableStats()

  // eslint-disable-next-line complexity
  const entries = useMemo<PortfolioEntry[]>(() => {
    if (
      tvlt_query.data == null ||
      tech_query.data == null ||
      vault_price_result.data == null ||
      tech_price_result.data == null ||
      fisn_price_result.data == null
    ) {
      return []
    }

    return [
      {
        ...tvlt_query.data,
        symbol: 'TVLT',
        ...calc_prices({
          balance: wallet_tvlt.balance,
          price_per_token: vault_price_result.data.price_per_token,
          total_paid: stats['TVLT'].total_paid,
        }),
        average_paid: stats['TVLT'].average_paid,
      } satisfies PortfolioEntry,
      {
        ...tech_query.data,
        symbol: 'TECH',
        ...calc_prices({
          balance: wallet_tech.balance,
          price_per_token: tech_price_result.data,
          total_paid: stats['TECH'].total_paid,
        }),
        average_paid: stats['TECH'].average_paid,
      } satisfies PortfolioEntry,
      {
        type: 'token',
        image_slug: 'fisn',
        symbol: 'FISN',
        ...calc_prices({
          balance: wallet_fisn.balance,
          price_per_token: fisn_price_result.data,
          total_paid: stats['FISN'].total_paid,
        }),
        average_paid: stats['FISN'].average_paid,
      } satisfies PortfolioEntry,
    ].filter((e) => e.tokens > 0 || e.type == 'fund')
  }, [
    fisn_price_result.data,
    stats,
    tech_price_result.data,
    tech_query.data,
    tvlt_query.data,
    vault_price_result.data,
    wallet_fisn.balance,
    wallet_tech.balance,
    wallet_tvlt.balance,
  ])

  const queries = [
    fisn_price_result,
    tech_price_result,
    tech_query,
    tvlt_query,
    vault_price_result,
    wallet_fisn,
    wallet_tech,
    wallet_tvlt,
  ]

  const progress = queries.reduce(
    (acc, curr) => acc + (curr.isSuccess ? 1 : 0),
    0,
  )

  return {
    isConnected,
    entries,
    totals: {
      usdc_value: _.sumBy(entries, 'usdc_value'),
      tokens: _.sumBy(entries, 'tokens'),
    },
    positions: positions_query.data?.positions ?? [],
    progress,
    total: queries.length,
    ...merge_queries([
      tvlt_query,
      tech_query,
      positions_query,
      tech_price_result,
      vault_price_result,
      wallet_tech,
      wallet_tvlt,
    ]),
  }
}
