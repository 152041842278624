import { memo, type ReactNode } from 'react'

import { ContentBox } from '../../../../apps/labs/src/components/ContentBox'

import classes from './ContentGeneralText.module.css'

export const ContentGeneralText = memo<{ children: ReactNode }>(
  function ContentGeneralText({ children }) {
    return (
      <ContentBox
        maw="100ex"
        w="100%"
        p="xl"
        mt="xl2"
        mx="auto"
        variant="bright"
        className={classes.root}
      >
        {children}
      </ContentBox>
    )
  },
)
