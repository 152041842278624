import * as _ from 'lodash-es'

import { amount_to_tokens, tokens_to_amount } from '../helpers/block_helpers'

import type { VaultBalances } from './vaults_block_queries'

export type DepositTokens = {
  deposit_usdc_tokens: number
  deposit_fund_tokens: number
}

// eslint-disable-next-line max-lines-per-function, complexity
export function find_deposits_from_deposit_total({
  deposit_total_usdc_value,
  fund_price_per_token,
  fund_decimal,
  usdc_decimal,
  balances,
}: {
  deposit_total_usdc_value: number
  fund_price_per_token: number
  fund_decimal: number
  usdc_decimal: number
  balances: VaultBalances
}): DepositTokens {
  if (balances.has_position == false) {
    const deposit_usdc_tokens = deposit_total_usdc_value / 2
    const deposit_fund_tokens =
      deposit_total_usdc_value / 2 / fund_price_per_token

    return {
      deposit_usdc_tokens,
      deposit_fund_tokens,
    }
  }

  const usdc_positions_tokens = amount_to_tokens(
    balances.usdc_positions_amount,
    usdc_decimal,
  )
  const fund_positions_tokens = amount_to_tokens(
    balances.fund_positions_amount,
    fund_decimal,
  )
  let usdc_balance_tokens = amount_to_tokens(
    balances.usdc_balance,
    usdc_decimal,
  )
  let fund_balance_tokens = amount_to_tokens(
    balances.fund_balance,
    fund_decimal,
  )

  const position_ratio_usdc_over_fund =
    usdc_positions_tokens / fund_positions_tokens

  // clear matching balances
  if (usdc_balance_tokens > 0 && fund_balance_tokens > 0) {
    const matching_fund_tokens =
      (usdc_balance_tokens * 1) / position_ratio_usdc_over_fund

    if (matching_fund_tokens <= fund_balance_tokens) {
      usdc_balance_tokens = 0
      fund_balance_tokens -= matching_fund_tokens
    } else {
      const matching_usdc_tokens =
        fund_balance_tokens * position_ratio_usdc_over_fund
      usdc_balance_tokens -= matching_usdc_tokens
      fund_balance_tokens = 0
    }
  }

  // check if less than balance
  if (usdc_balance_tokens > 0) {
    const matching_fund_tokens =
      (usdc_balance_tokens * 1) / position_ratio_usdc_over_fund
    const matching_value = matching_fund_tokens * fund_price_per_token
    if (matching_value >= deposit_total_usdc_value) {
      return {
        deposit_usdc_tokens: 0,
        deposit_fund_tokens: deposit_total_usdc_value / fund_price_per_token,
      }
    }
  }
  if (fund_balance_tokens > 0) {
    const matching_usdc_tokens =
      fund_balance_tokens * position_ratio_usdc_over_fund
    if (matching_usdc_tokens >= deposit_total_usdc_value) {
      return {
        deposit_usdc_tokens: matching_usdc_tokens,
        deposit_fund_tokens: 0,
      }
    }
  }

  // total is greater than balances
  const total_deposit_balance_value =
    deposit_total_usdc_value +
    usdc_balance_tokens +
    fund_balance_tokens * fund_price_per_token

  /* system of equations
  
  deposit_total_usdc_value = dt
  total_deposit_balance_value = T
  fund_price_per_token = p
  deposit_usdc_tokens = U
  deposit_fund_tokens = F
  usdc_balance_tokens = c
  fund_balance_tokens = d

  dt = p*F + U
  T = dt + c + p*d
  T = p*F + U + c + p*d
  T = U + c + p*(F+d)
  U = T - c - p*(F+d)
  r = (U + c) / (F + d)

  r = (T - p*(F + d)) / (F + d)

  F = (T - p*d - r*d) / (r + p)
  */

  const deposit_fund_tokens: number =
    (total_deposit_balance_value -
      fund_price_per_token * fund_balance_tokens -
      position_ratio_usdc_over_fund * fund_balance_tokens) /
    (position_ratio_usdc_over_fund + fund_price_per_token)

  const deposit_usdc_tokens =
    total_deposit_balance_value -
    usdc_balance_tokens -
    fund_price_per_token * (deposit_fund_tokens + fund_balance_tokens)

  return {
    deposit_usdc_tokens: correct_numbers(deposit_usdc_tokens),
    deposit_fund_tokens: correct_numbers(deposit_fund_tokens),
  }
}

function correct_numbers(tokens: number): number {
  const rounded = _.floor(tokens, 4)
  // fix -0 issue
  if (rounded.toString() == '0') return 0
  return rounded
}

export function find_matching_deposit_from_usdc({
  deposit_usdc_tokens,
  fund_price_per_token,
  fund_decimal,
  usdc_decimal,
  balances,
}: {
  deposit_usdc_tokens: number
  fund_price_per_token: number
  fund_decimal: number
  usdc_decimal: number
  balances: VaultBalances
}): DepositTokens {
  if (balances.has_position == false) {
    return {
      deposit_usdc_tokens,
      deposit_fund_tokens: deposit_usdc_tokens / fund_price_per_token,
    }
  }

  if (balances.usdc_positions_amount == 0n) {
    return {
      deposit_usdc_tokens: 0,
      deposit_fund_tokens: 0,
    }
  }

  const deposit_usdc_amount = tokens_to_amount(
    deposit_usdc_tokens,
    usdc_decimal,
  )

  // do math as integers!
  let matching_fund_amount =
    ((deposit_usdc_amount + balances.usdc_balance) *
      balances.fund_positions_amount) /
    balances.usdc_positions_amount

  if (matching_fund_amount > balances.fund_balance) {
    matching_fund_amount -= balances.fund_balance
  } else {
    matching_fund_amount = 0n
  }
  return {
    deposit_usdc_tokens,
    deposit_fund_tokens: correct_numbers(
      amount_to_tokens(matching_fund_amount, fund_decimal),
    ),
  }
}

export function find_matching_deposit_from_fund({
  deposit_fund_tokens,
  fund_price_per_token,
  fund_decimal,
  usdc_decimal,
  balances,
}: {
  deposit_fund_tokens: number
  fund_price_per_token: number
  fund_decimal: number
  usdc_decimal: number
  balances: VaultBalances
}): DepositTokens {
  if (balances.has_position == false) {
    return {
      deposit_usdc_tokens: deposit_fund_tokens * fund_price_per_token,
      deposit_fund_tokens,
    }
  }

  if (balances.fund_positions_amount == 0n) {
    return {
      deposit_usdc_tokens: 0,
      deposit_fund_tokens: 0,
    }
  }

  const deposit_fund_amount = tokens_to_amount(
    deposit_fund_tokens,
    fund_decimal,
  )

  // do math as integers!
  let matching_usdc_amount =
    ((deposit_fund_amount + balances.fund_balance) *
      balances.usdc_positions_amount) /
    balances.fund_positions_amount

  if (matching_usdc_amount > balances.usdc_balance) {
    matching_usdc_amount -= balances.usdc_balance
  } else {
    matching_usdc_amount = 0n
  }

  return {
    deposit_usdc_tokens: correct_numbers(
      amount_to_tokens(matching_usdc_amount, usdc_decimal),
    ),
    deposit_fund_tokens,
  }
}

export function find_max_deposits({
  usdc_max,
  fund_max,
  balance_usdc_tokens,
  balance_fund_tokens,
}: {
  usdc_max: DepositTokens
  fund_max: DepositTokens
  balance_usdc_tokens: number
  balance_fund_tokens: number
}): DepositTokens {
  if (
    usdc_max.deposit_fund_tokens <= balance_fund_tokens &&
    usdc_max.deposit_usdc_tokens <= balance_usdc_tokens
  ) {
    return usdc_max
  }
  return fund_max
}
