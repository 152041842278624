import { Anchor, Box, Title } from '@mantine/core'
import { createFileRoute } from '@tanstack/react-router'

import { ContentGeneralText } from '@repo/common/components/ContentGeneralText'

export const Route = createFileRoute('/_webpage/privacy-policy')({
  component: PrivacyPolicyPage,
})

// helpful site: https://html-cleaner.com/

// eslint-disable-next-line max-lines-per-function
function PrivacyPolicyPage() {
  return (
    <ContentGeneralText>
      <Title>Privacy Policy</Title>
      <p>
        <em>Last Updated: January 2024</em>
      </p>
      <p>
        This Privacy Policy (&quot;Policy&quot;) describes how Microcapital Inc.
        (&quot;Fission,&quot; &quot;we,&quot; &quot;us&quot; or &quot;our&quot;)
        may collect, use, and disclose information, and describes your choices
        regarding this information. Please read this Policy carefully and
        contact us with questions at{' '}
        <Anchor
          rel="noreferrer"
          target="_blank"
          href="mailto:hello@fission.xyz"
        >
          hello@fission.xyz
        </Anchor>
        .
      </p>
      <Title order={2}>Applicability of This Policy</Title>
      <p>
        This Policy applies to our services, which include the services we
        provide on our platform, user interface to the Protocol or any other
        websites, pages, features, mobile applications, or content we own or
        operate (collectively, the &quot;Sites&quot;) or when you use any
        Fission API or third-party applications relying on such an API, and
        related services (collectively, the &quot;Services&quot;). If you do not
        agree with the terms of this Policy, do not access or use the Services,
        Sites, or any other aspect of our business.
      </p>
      <Title order={2}>What We Collect</Title>
      <p>When you interact with our Services, we may collect:</p>
      <ul>
        <li>Contact Information, such as your email address.</li>
        <li>
          Financial Information, such as your Ethereum address, cryptocurrency
          wallet information, transaction history, and associated fees paid.
        </li>
        <li>
          Transaction Information, such as information about the transactions
          you make on our Services, such as the type of transaction, transaction
          amount, and timestamp.
        </li>
        <li>
          Correspondence, such as your feedback, questionnaire and other survey
          responses, and information you provide to our support teams, including
          via our help chat.
        </li>
        <li>
          Online Identifiers, such as geo location/tracking details, browser
          fingerprint, operating system, browser name and version, and/or
          personal IP addresses.
        </li>
        <li>
          Usage Data, such as user preferences and other data collected via
          cookies and similar technologies.
        </li>
        <li>
          Information We Get from Others. We may get information about you from
          other sources as required or permitted by applicable law, including
          public databases, credit bureaus &amp; ID verification partners. We
          may combine the information collected from these sources with the
          information we get from this Site in order to comply with our legal
          obligations and limit the use of our Services in connection with
          fraudulent or other illicit activities.
        </li>
        <li>
          Information from cookies and other tracking technologies. We and third
          parties we authorize may collect information about the use of the
          Services, as well as about our interactions with you. This information
          may include internet protocol (IP) addresses, browser type, internet
          service provider (ISP), referring/exit pages, operating system,
          date/time stamp, and clickstream data, and information about your
          interactions with the communications we send to you. We may combine
          this automatically collected log information with other information we
          collect about you. You may choose to set your web browser to refuse
          cookies, or to alert you when cookies are being sent. If you do so,
          please note that some parts of our Services may not function properly.
        </li>
      </ul>
      <Title order={2}>How We Use Information</Title>
      <p>
        We use your information in accordance with your instructions, including
        any applicable terms in the Terms of Use, and as required by applicable
        law. We may also use the information we collect for:
      </p>
      <Box pl="xl">
        <Title order={3}>Providing Services and Features</Title>
        <p>
          We may use the information we collect to provide, personalize,
          maintain, and improve our products and Services, including as we
          described in the Terms of Use. This includes using information to:
        </p>
        <ul>
          <li>
            operate, maintain, customize, measure, and improve our Services, and
            manage our business;
          </li>
          <li>process transactions;</li>
          <li>
            send information, including confirmations, notices, updates,
            security alerts, and support and administrative messages; and
          </li>
          <li>to create de-identified or aggregated data.</li>
        </ul>
        <Title order={3}>Safety and Security</Title>
        <p>
          We may use your information to help maintain the safety, security, and
          integrity of you and our Services, including to:
        </p>
        <ul>
          <li>
            protect, investigate, and deter against fraudulent, unauthorized, or
            illegal activity;
          </li>
          <li>
            monitor and verify identity or service access, combat spam, malware
            or security risks;
          </li>
          <li>
            perform internal operations necessary to provide our Services,
            including to troubleshoot software bugs and operational problems;
          </li>
          <li>
            enforce our agreements with third parties, and address violations of
            our Terms of Use or agreements for other Services; and
          </li>
          <li>comply with applicable security laws and regulations.</li>
        </ul>
        <Title order={3}>User Support</Title>
        <p>
          We may use information we collect to provide support, including to:
        </p>
        <ul>
          <li>direct questions to the appropriate support person;</li>
          <li>investigate and address user concerns; and</li>
          <li>
            monitor and improve our customer support responses and processes.
          </li>
        </ul>
        <Title order={3}>Research and Development</Title>
        <p>
          We may use the information we collect for testing, research, analysis,
          and product development to improve your experience. This helps us to
          improve and enhance the safety and security of our Services, improve
          our ability to prevent the use of our Services for illegal or improper
          purposes and develop new features and products relating to our
          Services.
        </p>
        <Title order={3}>Legal and Regulatory Compliance</Title>
        <p>
          We may verify your identity by comparing the personal information you
          provide against third-party databases and public records. We may use
          the information we collect to investigate or address claims or
          disputes relating to use of our Services, or as otherwise allowed by
          applicable law, or as requested by regulators, government entities,
          and official inquiries.
        </p>
        <Title order={3}>Direct Marketing</Title>
        <p>
          We may use the information we collect to market our Services to you.
          This may include sending you communications about our Services,
          features, promotions, surveys, news, updates, and events, and managing
          your participation in these promotions and events. If you do not want
          us to send you marketing communications, please opt out by clicking
          the &quot;unsubscribe&quot; link in any marketing email we send or by
          contacting us at{' '}
          <Anchor
            rel="noreferrer"
            target="_blank"
            href="mailto:hello@fission.xyz"
          >
            hello@fission.xyz
          </Anchor>
          .
        </p>
      </Box>
      <Title order={2}>How We Share &amp; Disclose Information</Title>
      <p>We may share your information in the following circumstances:</p>
      <ul>
        <li>
          To Comply with Our Legal Obligations. We may share your information:
          (A) to cooperate with government or regulatory investigations; (B)
          when we are compelled to do so by a subpoena, court order, or similar
          legal procedure; (C) when we believe in good faith that the disclosure
          of personal information is necessary to prevent harm to another
          person; (D) to report suspected illegal activity; or (E) to
          investigate violations of our User Agreement or any other applicable
          policies.
        </li>
        <li>
          With Service Providers. We may share your information with service
          providers who help facilitate business and compliance operations such.
          Our contracts require these service providers to only use your
          information in connection with the services they perform for us.
        </li>
        <li>
          During a Change to Our Business. If we engage in a merger,
          acquisition, bankruptcy, dissolution, reorganization, sale of some or
          all of our assets or stock, financing, public offering of securities,
          acquisition of all or a portion of our business, a similar transaction
          or proceeding, or steps in contemplation of such activities, some or
          all of your information may be shared or transferred, subject to
          standard confidentiality arrangements.
        </li>
        <li>
          Aggregated or De-identified Data. We may share aggregated and/or
          anonymized data with others.
        </li>
      </ul>
      <Title order={2}>Data Retention</Title>
      <p>
        To view or update your information, contact us at{' '}
        <Anchor
          rel="noreferrer"
          target="_blank"
          href="mailto:hello@fission.xyz"
        >
          hello@fission.xyz
        </Anchor>
        . We store your information throughout the life of your use of the
        Protocol and retain your information for a minimum of five (5) years to
        comply with our legal obligations and to resolve disputes. If you cease
        using the Protocol, we will neither use your information for any further
        purposes, nor sell or share your information with third parties, except
        as necessary to prevent fraud and assist law enforcement, as required by
        law, or in accordance with this Policy.
      </p>
      <Title order={2}>Security</Title>
      <p>
        To protect your personal information from unauthorized access and use,
        we use security measures that comply with applicable law. These measures
        include administrative, technical, and physical safeguards. However, no
        system can be completely secure. Therefore, although we take steps to
        secure your information, we cannot guarantee that your information,
        searches, or other communication will always remain secure. You are
        responsible for all activity on the Protocol relating to any of your
        Ethereum network addresses and/or cryptocurrency wallets.
      </p>
      <Title order={2}>Age Limitations</Title>
      <p>
        To the extent prohibited by applicable law, we do not allow use of our
        Services and Sites by anyone younger than the legal age in the
        jurisdiction in which the user resides. If you learn that anyone younger
        than the legal age has unlawfully provided us with personal data, please
        contact us at{' '}
        <Anchor
          rel="noreferrer"
          target="_blank"
          href="mailto:hello@fission.xyz"
        >
          hello@fission.xyz
        </Anchor>{' '}
        and we will take steps to delete such information, close any such
        accounts, and, to the extent possible, prevent the user from continuing
        to use our Services.
      </p>
      <Title order={2}>Changes to This Policy</Title>
      <p>
        If we make any changes, we will change the Last Updated date above. We
        encourage you to review this Policy to stay informed. If we make
        material changes, we will provide additional notice, such as via the
        email specified in your account or through the Services or Sites.
      </p>
      <Title order={2}>Reasons We Can Share Your Personal Information</Title>
      <p>
        We need to share users&apos; personal information to operate certain
        aspects of the Protocol and our business. Detailed below are the reasons
        we share your personal information and whether you can limit this
        sharing:
      </p>
      <ul>
        <li>
          We share users&apos; personal information for our everyday business
          purposes, such as to process and match your orders and respond to
          court orders and legal investigations. You cannot limit our sharing of
          this information.
        </li>
        <li>
          We share users&apos; personal information for our marketing purposes,
          such as to offer our products and services to you. You cannot limit
          our sharing of this information.
        </li>
        <li>
          We do not share users&apos; personal information for joint marketing
          with financial companies.
        </li>
        <li>
          We do not share users&apos; personal information for our
          affiliates&apos; everyday business purposes.
        </li>
        <li>
          We do not share users&apos; personal information for our affiliates to
          market to you.
        </li>
        <li>
          We do not share users&apos; personal information for non-affiliates to
          market to you.
        </li>
      </ul>
      <p>
        &quot;Affiliates&quot; refer to companies related by common ownership or
        control; &quot;non-affiliates&quot; refer to companies no related by
        common ownership or control; and &quot;joint marketing&quot; refers to a
        formal agreement between nonaffiliated financial companies that together
        market financial products or services to you.
      </p>
      <Title order={2}>How Does Fission Collect My Personal Information?</Title>
      <p>
        We collect your personal information, for example, when you deposit
        Digital Assets in the Protocol, make transactions using the Protocol, or
        withdraw Digital Assets from the Protocol. We may also collect your
        personal information from other companies.
      </p>
    </ContentGeneralText>
  )
}
