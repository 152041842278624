import { useMemo } from 'react'

import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import * as _ from 'lodash-es'
import { usePostHog } from 'posthog-js/react'
import { parseUnits } from 'viem'
import {
  useAccount,
  usePublicClient,
  useSwitchChain,
  useWriteContract,
} from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import type { TokenImageSlug } from '../../components/TokenImage'
import { merge_queries } from '../../helpers/query_helpers'
import { fissionVaultAbi } from '../blockchain/abis/fissionVaultAbi'
import { useCreateAndSignPermit2 } from '../blockchain/useCreateAndSignPermit2'
import { useGetDecimals } from '../blockchain/useGetDecimals'
import { keys as wallet_keys } from '../blockchain/useGetWalletBalance'
import {
  set_looking_for_change,
  useGetWalletBalance,
} from '../blockchain/useGetWalletBalance'
import { useListCryptoContracts } from '../crypto_contracts'
import { graphql_call } from '../helpers/server_query'
import { keys as portfolio_keys } from '../portfolio'

import { query_list_products } from './products_graphql'

export const keys = createQueryKeyStore({
  products: {
    all: null,
  },
})

export type ProductSymbol = 'TVLT' | 'TECH'
export type TokenSymbol = 'USDC' | 'FISN' | ProductSymbol

export type TokenType = {
  symbol: TokenSymbol
  token_address: CryptoAddress
  image_slug: TokenImageSlug
  name: string
  long_name: string
}

export type ProductType = {
  id: RubyID
  type: 'vault' | 'fund'
  minter_address: CryptoAddress
  minter_history: {
    address: CryptoAddress
    version: 'v1' | 'v2'
  }[]
  price_per_share: number
} & TokenType

export type ProductFindBy = {
  id?: RubyID
  symbol?: string
}

function convertProductType(type: string): ProductType['type'] {
  if (type == null) return 'fund'

  return type.split('_')[1] as ProductType['type']
}

export function useListProducts() {
  const results = useQuery<ProductType[]>({
    queryKey: keys.products.all.queryKey,
    queryFn: async () => {
      const data = await graphql_call({ query: query_list_products })

      if (data == null) return []

      return data
        .map((p) => {
          if (
            p.minter?.address == null ||
            p.token_address == null ||
            p.type == null
          ) {
            return null
          }

          return {
            ...p,
            minter_address: p.minter.address,
            type: convertProductType(p.type),
          } as ProductType
        })
        .filter((p): p is ProductType => p != null)
    },
    staleTime: 60 * 60 * 1000, // 1 hour
    gcTime: Infinity,
  })

  const data = results.data ?? []

  const product_find_by = ({ id, symbol }: ProductFindBy) =>
    data.find((f) => f.id == id || f.symbol === symbol?.toUpperCase())

  return {
    ...results,
    data,
    product_find_by,
  }
}
export function useProductInfo(args: ProductFindBy) {
  const { product_find_by, ...others } = useListProducts()

  return { ...others, data: product_find_by(args) }
}

export type TokenFindBy = {
  symbol?: string
}

export function useListTokens() {
  const products = useListProducts()
  const contracts = useListCryptoContracts()

  const all_tokens: TokenType[] = useMemo(() => {
    const filter_contracts = contracts.data.contracts
      .filter((c) => c.category == 'token')
      .map((c) => ({
        symbol: c.symbol! as TokenSymbol,
        token_address: c.address,
        image_slug: c.symbol!.toLowerCase() as TokenImageSlug,
        name: c.name,
        long_name: c.name,
      }))

    return [...products.data, ...filter_contracts]
  }, [products.data, contracts.data])

  const token_find_by = ({ symbol }: TokenFindBy) =>
    all_tokens.find((f) => f.symbol.toUpperCase() === symbol?.toUpperCase())

  return {
    ...merge_queries([products, contracts]),
    token_find_by,
    all_tokens,
  }
}
export function useTokenInfo(args: TokenFindBy) {
  const { token_find_by, ...others } = useListTokens()

  return { ...others, data: token_find_by(args) }
}

// eslint-disable-next-line complexity, max-lines-per-function
export function useBuyVaultProduct({ id }: { id: RubyID }) {
  const queryClient = useQueryClient()
  const { writeContractAsync } = useWriteContract()
  const { switchChainAsync } = useSwitchChain()
  const { wallet_address, decimals: usdc_decimals } =
    useGetWalletBalance('USDC')
  const { decimals: tech_decimals } = useGetWalletBalance('TECH')
  const client = usePublicClient({ chainId: active_chain.id })
  const { createDoubleAsync, isReady: permit_is_ready } =
    useCreateAndSignPermit2()
  const posthog = usePostHog()

  const { data: product } = useProductInfo({ id })
  const minter_address = product?.minter_address
  const usdc_address = useTokenInfo({ symbol: 'USDC' }).data?.token_address
  const tech_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address

  const isReady =
    permit_is_ready &&
    wallet_address != null &&
    usdc_decimals != null &&
    tech_decimals != null &&
    usdc_address != null &&
    tech_address != null &&
    minter_address != null &&
    product != null &&
    client != null

  const result = useMutation({
    mutationFn: async ({
      deposit_usdc_tokens,
      deposit_fund_tokens,
    }: {
      deposit_usdc_tokens: number
      deposit_fund_tokens: number
    }) => {
      if (!isReady) return

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const { permitDouble, signature } = await createDoubleAsync({
        tokens: [
          {
            symbol: 'USDC',
            address: usdc_address,
            amount: parseUnits(deposit_usdc_tokens.toString(), usdc_decimals),
          },
          {
            symbol: 'TECH',
            address: tech_address,
            amount: parseUnits(deposit_fund_tokens.toString(), usdc_decimals),
          },
        ],
        destination_address: minter_address,
      })

      const tx = await writeContractAsync({
        abi: fissionVaultAbi,
        address: minter_address,
        functionName: 'mint_vault',
        args: [permitDouble, signature],
        chainId: active_chain.id,
      })

      await client.waitForTransactionReceipt({ hash: tx })
      posthog.capture('vault minted', {
        fund: product.symbol,
        deposit_usdc_tokens,
        deposit_fund_tokens,
      })
    },
    onSuccess: async () => {
      set_looking_for_change(product!.token_address)
      await queryClient.invalidateQueries({
        queryKey: wallet_keys.balance.wallet(wallet_address!).queryKey,
      })

      await queryClient.invalidateQueries({
        queryKey: portfolio_keys.portfolio._def,
      })
    },
  })
  return {
    ...result,
    isReady,
  }
}

// eslint-disable-next-line complexity
export function useSellVault({ id }: { id: RubyID }) {
  const queryClient = useQueryClient()
  const { writeContractAsync } = useWriteContract()
  const { switchChainAsync } = useSwitchChain()
  const { data: vault } = useProductInfo({ id })
  const { data: vault_decimals } = useGetDecimals(vault?.token_address)
  const client = usePublicClient({ chainId: active_chain.id })
  const { address: wallet_address } = useAccount()

  const isReady =
    client != null &&
    vault != null &&
    vault_decimals != null &&
    wallet_address != null

  const result = useMutation({
    mutationFn: async ({ tokens }: { tokens: number }) => {
      if (!isReady) return

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const tx = await writeContractAsync({
        abi: fissionVaultAbi,
        address: vault.minter_address,
        functionName: 'redeem_vault',
        chainId: active_chain.id,
        args: [parseUnits(tokens.toString(), vault_decimals)],
      })

      await client.waitForTransactionReceipt({ hash: tx })
    },
    onSuccess: async () => {
      set_looking_for_change(vault!.token_address)
      await queryClient.invalidateQueries({
        queryKey: wallet_keys.balance.wallet(wallet_address!).queryKey,
      })
    },
  })
  return {
    ...result,
    isReady,
  }
}
/*

  const { data: blockNumber } = useBlockNumber({ watch: true })
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: balance_results.queryKey })
  }, [balance_results.queryKey, blockNumber, queryClient])
*/
