import { type LegacyRef, memo, type ReactNode, useEffect } from 'react'

import {
  ActionIcon,
  Box,
  Button,
  type FlexProps,
  Group,
  NumberInput,
  Select,
  type SelectProps,
  Stack,
  Text,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import {
  useDebouncedValue,
  useDisclosure,
  useFocusWithin,
} from '@mantine/hooks'
import { z } from 'zod'

import { IconArrowDown, IconArrowsDownUp } from '@repo/common/components/Icons'
import {
  symbol_to_render_props,
  TokenRender,
} from '@repo/common/components/TokenRender'
import { countFormatter } from '@repo/common/helpers/formatters'
import { usdFormatter } from '@repo/common/helpers/formatters'
import { useGetWalletBalance } from '@repo/common/queries/blockchain/useGetWalletBalance'
import {
  useCheckPermit2,
  useSetupPermit2,
} from '@repo/common/queries/blockchain/useSetupPermit2'
import {
  type ExchangeSideType,
  type InSideType,
  useGetTokenSwapPrice,
  useSwap,
} from '@repo/common/queries/fission_dex'
import type { TokenSymbol } from '@repo/common/queries/products'

import { ContentBox } from '../../../../apps/labs/src/components/ContentBox'
import { ActionHideShow } from '../ActionHideShow'
import { ConnectWalletButton } from '../ConnectWalletButton'
import { WalletExecuteButton } from '../WalletExecuteButton'

import classes from './Swap.module.css'

export type SwapableTokens = 'TECH'
const valid_tokens: SwapableTokens[] = ['TECH'] as const
const swap_tokens = ['USDC', 'TECH'] as const

export function is_valid_symbol(symbol: string): SwapableTokens | null {
  if (valid_tokens.includes(symbol.toUpperCase() as SwapableTokens)) {
    return symbol.toUpperCase() as SwapableTokens
  }
  return null
}

const default_reason = 'Enter an amount'

export type ContentBoxType = (
  props: FlexProps & {
    ref?: LegacyRef<HTMLDivElement>
  },
) => ReactNode

export const Swap = memo<{
  token_symbol: SwapableTokens
  in_side: InSideType
  onCompleted: () => void
  // eslint-disable-next-line max-lines-per-function, complexity
}>(function Swap({ token_symbol, in_side, onCompleted }) {
  const form = useForm({
    mode: 'controlled',
    initialValues: {
      in_side,
      exchange_side: 'in',
      amount_in: undefined,
      amount_out: undefined,
      symbol_in: in_side === 'USDC' ? 'USDC' : token_symbol,
      symbol_out: in_side !== 'USDC' ? 'USDC' : token_symbol,
    } as {
      in_side: InSideType
      exchange_side: ExchangeSideType
      amount_in: number | undefined
      amount_out: number | undefined
      symbol_in: TokenSymbol
      symbol_out: TokenSymbol
    },
    validateInputOnChange: true,
    validate: (values) => {
      return zodResolver(get_schema(values))(values)
    },
  })
  const get_schema = (values: {
    amount_in: number | undefined
    amount_out: number | undefined
    symbol_in: TokenSymbol
    symbol_out: TokenSymbol
  }) => {
    return z.object({
      amount_in: z
        .number({ message: default_reason })
        .positive()
        .max(balance_in, {
          message: `Insufficient ${values.symbol_in} balance`,
        }),
      amount_out: z.number({ message: default_reason }).positive(),
    })
  }

  const [show_details, show_details_actions] = useDisclosure(false)
  const [debounced_form_values] = useDebouncedValue(form.getValues(), 500)
  const { data: swap_data } = useGetTokenSwapPrice(debounced_form_values)

  // needs to default to true to prevent disabling input when fetching new swap data
  const has_liquidity = swap_data?.has_liquidity ?? true

  const { balance: balance_in, isConnected } = useGetWalletBalance(
    form.getValues().symbol_in,
  )
  const { balance: balance_out } = useGetWalletBalance(
    form.getValues().symbol_out,
  )

  const permit_query = useCheckPermit2({ symbol: form.getValues().symbol_in })
  const setup_permit = useSetupPermit2({ symbol: form.getValues().symbol_in })

  const swap_query = useSwap({
    symbol_in: form.getValues().symbol_in,
    symbol_out: form.getValues().symbol_out,
  })

  const onSubmit = (
    values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
  ) => {
    swap_query
      .mutateAsync({ amount: values.amount_in! })
      .then(onCompleted)
      .catch(console.error)
  }

  const amount_in_focus = useFocusWithin()
  const amount_out_focus = useFocusWithin()

  form.watch('symbol_in', ({ previousValue, value }) => {
    const { amount_in, amount_out } = form.getValues()

    form.setValues({
      in_side: value === 'USDC' ? 'USDC' : 'TOKEN',
      amount_in: amount_out,
      amount_out: amount_in,
      symbol_out: previousValue,
    })
  })

  form.watch('symbol_out', ({ previousValue }) => {
    form.setFieldValue('symbol_in', previousValue)
  })

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (swap_data?.usdc_price == null || swap_data?.usdc_price <= 0) return

    if (form.getValues().exchange_side === 'in') {
      const value = form.getValues().amount_in
      if (value == null) return

      form.setValues({
        amount_out:
          form.getValues().symbol_in == 'USDC'
            ? value / swap_data.usdc_price
            : value * swap_data.usdc_price,
      })
    } else {
      const value = form.getValues().amount_out
      if (value == null) return

      form.setValues({
        amount_in:
          form.getValues().symbol_in == 'USDC'
            ? value * swap_data.usdc_price
            : value / swap_data.usdc_price,
      })
    }
    // form changes every time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swap_data?.usdc_price])

  form.watch('amount_in', ({ value }) => {
    if (!amount_in_focus.focused) return
    if (swap_data?.usdc_price == null || swap_data?.usdc_price <= 0) {
      form.setValues({ exchange_side: 'in' })
      return
    }
    if (value == null) return

    if (form.getValues().symbol_in == 'USDC') {
      form.setValues({
        amount_out: value / swap_data.usdc_price,
        exchange_side: 'in',
      })
    } else {
      form.setValues({
        amount_out: value * swap_data.usdc_price,
        exchange_side: 'in',
      })
    }
  })

  form.watch('amount_out', ({ value }) => {
    if (!amount_out_focus.focused) return
    if (swap_data?.usdc_price == null || swap_data?.usdc_price <= 0) {
      form.setValues({ exchange_side: 'out' })
      return
    }
    if (value == null) return

    if (form.getValues().symbol_in == 'USDC') {
      form.setValues({
        amount_in: value * swap_data.usdc_price,
        exchange_side: 'out',
      })
    } else {
      form.setValues({
        amount_in: value / swap_data.usdc_price,
        exchange_side: 'out',
      })
    }
  })

  const enabled = form.isValid() && has_liquidity

  const reason = has_liquidity
    ? get_schema(form.getValues()).safeParse(form.getValues()).error?.errors[0]
        ?.message || default_reason
    : 'Insufficient liquidity'

  let button_content
  if (isConnected == false) {
    button_content = (
      <ConnectWalletButton size="xl" message="Connect Wallet First" />
    )
  } else if (permit_query.data?.permit_enabled == false) {
    button_content = (
      <WalletExecuteButton
        size="xl"
        variant="gradient"
        onClick={() => setup_permit.mutate()}
        loading={permit_query.isPending || setup_permit.isPending}
      >
        Enable {form.getValues().symbol_in} permissions
      </WalletExecuteButton>
    )
  } else {
    button_content = (
      <WalletExecuteButton
        disabled={!enabled}
        loading={swap_query.isPending || permit_query.isPending}
        variant={enabled ? 'gradient' : 'outline'}
        size="xl"
        type="submit"
      >
        {enabled ? 'Swap' : reason}
      </WalletExecuteButton>
    )
  }

  return (
    <Stack maw="100%">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack
          maw="calc(100vw - 2 * var(--app-body-margin))"
          w="500px"
          gap="xxs"
        >
          <Box pos="relative">
            <ActionIcon
              className={classes.switch_button}
              variant="filled"
              onClick={() =>
                form.setFieldValue('symbol_in', form.getValues().symbol_out)
              }
            >
              <IconArrowsDownUp
                style={{ width: '70%', height: '70%' }}
                stroke={1.5}
              />
            </ActionIcon>
            <ContentBox
              p="lg"
              gap="xs"
              ref={amount_in_focus.ref}
              mod={{ focused: amount_in_focus.focused }}
            >
              <Text size="lg" lh="1" opacity={0.7} mb="lg">
                Sell
              </Text>
              <Group justify="space-between">
                <NumberInput
                  classNames={{ input: classes.input }}
                  {...form.getInputProps('amount_in', { withError: false })}
                  disabled={!has_liquidity}
                  aria-label="sell amount"
                  variant="unstyled"
                  allowNegative={false}
                  decimalScale={form.getValues().symbol_in == 'USDC' ? 2 : 4}
                  hideControls
                  placeholder="0"
                  flex="1 1 200px"
                />
                <Select
                  {...form.getInputProps('symbol_in')}
                  aria-label="sell token"
                  w="130px"
                  size="md"
                  data={swap_tokens}
                  classNames={{
                    input: classes.select,
                    dropdown: classes.select_dropdown,
                  }}
                  allowDeselect={false}
                  renderOption={renderSelectOption}
                  rightSection={<IconArrowDown size="16px" />}
                  leftSection={
                    <TokenRender
                      size="var(--mantine-font-size-lg)"
                      {...symbol_to_render_props[form.getValues().symbol_in]}
                    />
                  }
                />
              </Group>
              <Group justify="flex-end" align="baseline" gap="0">
                <Text size="md" opacity={0.7}>
                  Balance:{' '}
                  {countFormatter(balance_in, {
                    compact: false,
                    maximumFractionDigits:
                      form.getValues().symbol_in == 'USDC' ? 2 : 4,
                  })}
                </Text>
                <Button
                  size="compact-md"
                  variant="subtle"
                  onClick={() => form.setFieldValue('amount_in', balance_in)}
                >
                  MAX
                </Button>
              </Group>
            </ContentBox>
          </Box>
          <ContentBox
            p="lg"
            gap="xs"
            ref={amount_out_focus.ref}
            mod={{ focused: amount_out_focus.focused }}
          >
            <Text size="lg" lh="1" opacity={0.7} mb="lg">
              Buy
            </Text>
            <Group justify="space-between">
              <NumberInput
                classNames={{ input: classes.input }}
                {...form.getInputProps('amount_out', { withError: false })}
                disabled={!has_liquidity}
                aria-label="buy amount"
                variant="unstyled"
                allowNegative={false}
                decimalScale={form.getValues().symbol_out == 'USDC' ? 2 : 4}
                hideControls
                placeholder="0"
                flex="1 1 200px"
              />
              <Select
                {...form.getInputProps('symbol_out')}
                aria-label="buy token"
                w="130px"
                size="md"
                data={swap_tokens}
                classNames={{
                  input: classes.select,
                  dropdown: classes.select_dropdown,
                }}
                allowDeselect={false}
                renderOption={renderSelectOption}
                rightSection={<IconArrowDown size="16px" />}
                leftSection={
                  <TokenRender
                    size="var(--mantine-font-size-lg)"
                    {...symbol_to_render_props[form.getValues().symbol_in]}
                  />
                }
              />
            </Group>
            <Group justify="flex-end">
              <Text size="md" opacity={0.7}>
                Balance:{' '}
                {countFormatter(balance_out, {
                  compact: false,
                  maximumFractionDigits:
                    form.getValues().symbol_out == 'USDC' ? 2 : 4,
                })}
              </Text>
            </Group>
          </ContentBox>
          {button_content}
          <Group pl="sm" justify="space-between">
            {has_liquidity && (
              <Text size="md" opacity={0.7}>
                1 {token_symbol} ={' '}
                {countFormatter(swap_data?.usdc_price ?? 0, {
                  compact: false,
                  maximumFractionDigits: 6,
                })}{' '}
                USDC
              </Text>
            )}
            <ActionHideShow
              toggle={show_details_actions.toggle}
              opened={show_details}
              item_noun="swap disclosures"
            />
          </Group>
          {show_details && (
            <Stack gap="xs" px="sm">
              <Group justify="space-between">
                <Text size="sm" opacity={0.7}>
                  Max. slippage
                </Text>
                <Text size="sm">5%</Text>
              </Group>
              <Group justify="space-between">
                <Text size="sm" opacity={0.7}>
                  System Fee (0.25%)
                </Text>
                <Text size="sm">
                  {usdFormatter(swap_data?.system_fee_amount)}
                </Text>
              </Group>
            </Stack>
          )}
        </Stack>
      </form>
    </Stack>
  )
})

const renderSelectOption: SelectProps['renderOption'] = ({ option }) => (
  <Group flex="1" gap="xxs">
    <TokenRender
      size="var(--mantine-font-size-lg)"
      {...symbol_to_render_props[option.value as TokenSymbol]}
    />
    {option.label}
  </Group>
)
