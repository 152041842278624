import { memo, useEffect, useRef, useState } from 'react'

import { useInterval } from '@mantine/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { local_date_time_formatter } from '../../helpers/formatters'

dayjs.extend(relativeTime)

const SWITCH_MODE_AFTER_DAYS = 2
export const resent_or_local_date_time_formatter = (
  value?: dayjs.ConfigType,
) => {
  const time = dayjs(value)

  if (time.isBefore(dayjs().subtract(SWITCH_MODE_AFTER_DAYS, 'days'))) {
    return local_date_time_formatter(time)
  }

  return time.fromNow()
}

export const RecentDateTime = memo<{ value: dayjs.ConfigType }>(
  function RecentDateTime({ value }) {
    const date_time = dayjs(value)

    const is_relative = !date_time.isBefore(
      dayjs().subtract(SWITCH_MODE_AFTER_DAYS, 'days'),
    )

    const [text, setText] = useState(
      resent_or_local_date_time_formatter(date_time),
    )
    const interval_ref = useRef<ReturnType<typeof useInterval>>()

    interval_ref.current = useInterval(
      () => setText(resent_or_local_date_time_formatter(date_time)),
      1000,
      { autoInvoke: is_relative },
    )

    useEffect(() => {
      if (!is_relative) interval_ref.current!.stop()
    }, [is_relative])

    return <>{text}</>
  },
)
