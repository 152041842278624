import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import posthog from 'posthog-js'

import { is_main_site } from '@repo/common/hooks/flags'
import { useAuth } from '@repo/common/queries/auth'

import { router } from './routes'

export function get_environment() {
  if (is_main_site()) {
    return 'production'
  } else if (window.location.hostname.includes('testnet')) {
    return 'testnet'
  } else if (window.location.hostname.includes('stage')) {
    return 'stage'
  }
  return 'development'
}

// no localhost error tracking
if (import.meta.env.MODE != 'development') {
  Sentry.init({
    environment: get_environment(),
    dsn: 'https://34011000490119eb38d649d6d65fddcb@o4507572175241216.ingest.us.sentry.io/4507589706055680',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['fission'],
        // eslint-disable-next-line @cspell/spellchecker
        behaviour: 'apply-tag-if-contains-third-party-frames',
      }),
      Sentry.captureConsoleIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      posthog.sentryIntegration({
        organization: 'fission-xyz',
        projectId: 4507589706055680,
      }),
      Sentry.feedbackIntegration({
        colorScheme: 'dark',
        autoInject: false,
        isEmailRequired: true,
        useSentryUser: {
          name: 'name',
          email: 'email',
        },
      }),
    ],
    release: __VERSION__,
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/.*.fission.xyz/],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

export function App() {
  const queryClient = useQueryClient()
  const { data: auth } = useAuth()

  return (
    <RouterProvider
      router={router}
      context={{
        queryClient,
        auth,
      }}
    />
  )
}
