/* eslint-disable complexity */
import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { Trade as RouterTrade } from '@uniswap/router-sdk'
import {
  type BigintIsh,
  CurrencyAmount,
  Percent,
  Price,
  Token,
} from '@uniswap/sdk-core'
import { SwapRouter } from '@uniswap/universal-router-sdk'
import {
  FeeAmount,
  type MintOptions,
  nearestUsableTick,
  NonfungiblePositionManager,
  Pool,
  Position,
  priceToClosestTick,
  Route as RouteV3,
  TICK_SPACINGS,
  TickMath,
  tickToPrice,
  Trade as V3Trade,
} from '@uniswap/v3-sdk'
import { erc20Abi } from 'abitype/abis'
import * as _ from 'lodash-es'
import { usePostHog } from 'posthog-js/react'
import { type Hex, parseUnits } from 'viem'
import {
  useAccount,
  usePublicClient,
  useSendTransaction,
  useSwitchChain,
  useWriteContract,
} from 'wagmi'

import { active_chain } from '@repo/common/blockchain/config'

import { positionManagerAbi } from '../blockchain/abis/positionManagerAbi'
import { quoterV2Abi } from '../blockchain/abis/quoterV2Abi'
import { uniswapV3FactoryAbi } from '../blockchain/abis/uniswapV3FactoryAbi'
import { uniswapV3PoolAbi } from '../blockchain/abis/UniswapV3Pool'
import { useCreateAndSignPermit2 } from '../blockchain/useCreateAndSignPermit2'
import { useGetDecimals } from '../blockchain/useGetDecimals'
import { useGetWalletBalance } from '../blockchain/useGetWalletBalance'
import { keys as wallet_keys } from '../blockchain/useGetWalletBalance'
import { useGetContractAddress } from '../crypto_contracts'
import { amount_to_tokens, findCurrencyAmount } from '../helpers/block_helpers'
import {
  type TokenSymbol,
  type TokenType,
  useListTokens,
  useTokenInfo,
} from '../products'

import {
  type ExchangeSideType,
  type InSideType,
  keys,
} from './fission_dex_queries'

// eslint-disable-next-line complexity, max-lines-per-function
export function useGetTokenSwapPrice({
  in_side,
  exchange_side,
  symbol_in,
  symbol_out,
  amount_in,
  amount_out,
}: {
  in_side: InSideType
  exchange_side: ExchangeSideType
  symbol_in: undefined | TokenSymbol
  symbol_out: undefined | TokenSymbol
  amount_in: undefined | number
  amount_out: undefined | number
}) {
  const client = usePublicClient({ chainId: active_chain.id })
  const { token_find_by } = useListTokens()
  const symbol = in_side == 'TOKEN' ? symbol_in : symbol_out
  const token_address = token_find_by({ symbol })?.token_address
  const usdc_address = token_find_by({ symbol: 'USDC' })?.token_address
  const { data: usdc_decimals } = useGetDecimals(usdc_address)
  const { data: token_decimals } = useGetDecimals(token_address)
  const quoter_address = useGetContractAddress('QUOTERV2')
  const { data: pool } = useGetPoolConfig('TECH')

  const amount = (exchange_side == 'in' ? amount_in : amount_out) || 1

  const tokenIn = in_side == 'USDC' ? usdc_address : token_address
  const tokenOut = in_side == 'USDC' ? token_address : usdc_address
  const in_decimals = in_side == 'USDC' ? usdc_decimals : token_decimals
  const out_decimals = in_side == 'USDC' ? token_decimals : usdc_decimals

  const enabled =
    client != null &&
    symbol != null &&
    tokenIn != null &&
    tokenOut != null &&
    in_decimals != null &&
    out_decimals != null &&
    amount != null &&
    quoter_address != null &&
    pool != null

  // usdc in, sell amount => quoteExactInputSingle, price = amount_in / other_amount
  // token in, sell amount => quoteExactInputSingle, price = other_amount / amount_in
  // usdc in, buy amount => quoteExactOutputSingle, price = amount_out / other_amount
  // token in, buy amount => quoteExactOutputSingle, price = other_amount / amount_out

  return useQuery<{
    usdc_price: number
    has_liquidity: boolean
    system_fee_amount: number
  }>({
    queryKey:
      symbol == null || amount == null
        ? []
        : keys.dex.swap_prices({ symbol, in_side, exchange_side, amount })
            .queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          if (pool.liquidity.toString() == '0') {
            return { usdc_price: 0, has_liquidity: false, system_fee_amount: 0 }
          }
          const system_fee_ratio = swap_system_fee_percentage / 100

          if (exchange_side == 'in') {
            const { result } = await client.simulateContract({
              abi: quoterV2Abi,
              functionName: 'quoteExactInputSingle',
              address: quoter_address,
              args: [
                {
                  tokenIn,
                  tokenOut,
                  amountIn: parseUnits(amount.toString(), in_decimals),
                  fee: FeeAmount.HIGH,
                  sqrtPriceLimitX96: 0n,
                },
              ],
            })
            const other_amount = amount_to_tokens(result[0], out_decimals)

            const usdc_price =
              in_side == 'USDC' ? amount / other_amount : other_amount / amount
            const system_fee_amount =
              in_side == 'USDC'
                ? amount * system_fee_ratio
                : amount * usdc_price * system_fee_ratio

            return { usdc_price, has_liquidity: true, system_fee_amount }
          }

          const { result } = await client.simulateContract({
            abi: quoterV2Abi,
            functionName: 'quoteExactOutputSingle',
            address: quoter_address,
            args: [
              {
                tokenIn,
                tokenOut,
                amount: parseUnits(amount.toString(), out_decimals),
                fee: FeeAmount.HIGH,
                sqrtPriceLimitX96: 0n,
              },
            ],
          })
          const other_amount = amount_to_tokens(result[0], in_decimals)
          const usdc_price =
            in_side == 'USDC' ? other_amount / amount : amount / other_amount

          const system_fee_amount =
            in_side == 'USDC'
              ? amount * system_fee_ratio
              : amount * usdc_price * system_fee_ratio
          return { usdc_price, has_liquidity: true, system_fee_amount }
        },
    staleTime: 5 * 1000, // 5 seconds
    gcTime: 5 * 60 * 1000, // 5 minutes
  })
}

function convert_to_u_token(token: TokenType, decimals: number): Token {
  return new Token(
    active_chain.id,
    token.token_address,
    decimals,
    token.symbol,
    token.long_name,
  )
}

export function useGetPoolAddress(token_symbol: undefined | TokenSymbol) {
  const client = usePublicClient({ chainId: active_chain.id })
  const { token_find_by } = useListTokens()
  const other_token = token_find_by({ symbol: token_symbol })
  const usdc_token = token_find_by({ symbol: 'USDC' })
  const dex_address = useGetContractAddress('DEX')

  const enabled =
    client != null &&
    other_token != null &&
    usdc_token != null &&
    dex_address != null &&
    token_symbol != null

  return useQuery<CryptoAddress>({
    queryKey: keys.dex.pool_address(token_symbol!).queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          return await client.readContract({
            address: dex_address,
            abi: uniswapV3FactoryAbi,
            functionName: 'getPool',
            args: [
              other_token.token_address,
              usdc_token.token_address,
              FeeAmount.HIGH,
            ],
          })
        },
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

// eslint-disable-next-line complexity
export function useGetPoolConfig(token_symbol: undefined | TokenSymbol) {
  const client = usePublicClient({ chainId: active_chain.id })
  const { token_find_by } = useListTokens()
  const other_token = token_find_by({ symbol: token_symbol })
  const { data: other_decimals } = useGetDecimals(other_token?.token_address)
  const usdc_token = token_find_by({ symbol: 'USDC' })
  const { data: usdc_decimals } = useGetDecimals(usdc_token?.token_address)
  const dex_address = useGetContractAddress('DEX')
  const { data: pool_address } = useGetPoolAddress(token_symbol)

  const enabled =
    client != null &&
    other_token != null &&
    other_decimals != null &&
    usdc_token != null &&
    usdc_decimals != null &&
    dex_address != null &&
    pool_address != null &&
    token_symbol != null

  return useQuery<Pool>({
    queryKey: keys.dex.pool_config(token_symbol!).queryKey,
    queryFn: !enabled
      ? skipToken
      : async () => {
          const other_u_token = convert_to_u_token(other_token, other_decimals)
          const usdc_u_token = convert_to_u_token(usdc_token, usdc_decimals)

          const [liquidity, slot0] = await Promise.all([
            client.readContract({
              address: pool_address,
              abi: uniswapV3PoolAbi,
              functionName: 'liquidity',
            }),
            client.readContract({
              address: pool_address,
              abi: uniswapV3PoolAbi,
              functionName: 'slot0',
            }),
          ])

          return new Pool(
            other_u_token,
            usdc_u_token,
            FeeAmount.HIGH,
            slot0[0].toString(),
            liquidity.toString(),
            slot0[1],
            [
              {
                index: nearestUsableTick(
                  TickMath.MIN_TICK,
                  TICK_SPACINGS[FeeAmount.HIGH],
                ),
                liquidityNet: liquidity.toString(),
                liquidityGross: liquidity.toString(),
              },
              {
                index: nearestUsableTick(
                  TickMath.MAX_TICK,
                  TICK_SPACINGS[FeeAmount.HIGH],
                ),
                liquidityNet: `-${liquidity.toString()}`,
                liquidityGross: liquidity.toString(),
              },
            ],
          )
        },
    staleTime: 30 * 1000, // 30 seconds
    gcTime: Infinity,
  })
}

const swap_slip_tolerance_percentage = 5
const swap_system_fee_percentage = 0.25
// eslint-disable-next-line max-lines-per-function
export function useSwap(base: {
  symbol_in: TokenSymbol
  symbol_out: TokenSymbol
}) {
  const send_transaction = useSendTransaction()
  const { switchChainAsync } = useSwitchChain()
  const client = usePublicClient({ chainId: active_chain.id })
  const { token_find_by } = useListTokens()
  const token_in_address = token_find_by({
    symbol: base.symbol_in,
  })?.token_address
  const { data: token_in_decimals } = useGetDecimals(token_in_address)
  const token_out_address = token_find_by({
    symbol: base.symbol_out,
  })?.token_address
  const { data: token_out_decimals } = useGetDecimals(token_out_address)
  const universal_router_address = useGetContractAddress('UNIVERSAL_ROUTER')
  const { data: pool } = useGetPoolConfig(
    base.symbol_in != 'USDC' ? base.symbol_in : base.symbol_out,
  )
  const queryClient = useQueryClient()
  const { address: wallet_address } = useAccount()
  const posthog = usePostHog()
  const { createSingleAsync } = useCreateAndSignPermit2()

  // eslint-disable-next-line max-lines-per-function
  return useMutation({
    // eslint-disable-next-line complexity, max-lines-per-function
    mutationFn: async (args: { amount: number }) => {
      // TODO protect against base not matching args

      if (
        token_in_address == null ||
        token_out_address == null ||
        client == null ||
        token_in_decimals == null ||
        token_out_decimals == null ||
        universal_router_address == null ||
        pool == null
      ) {
        console.warn('swap called before ready')
        return
      }

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const raw_amount = parseUnits(args.amount.toString(), token_in_decimals)

      const token_in = new Token(
        active_chain.id,
        token_in_address,
        token_in_decimals,
      )
      const token_out = new Token(
        active_chain.id,
        token_out_address,
        token_out_decimals,
      )

      const route = new RouteV3([pool], token_in, token_out)
      const trade = await V3Trade.exactIn(
        route,
        CurrencyAmount.fromRawAmount(token_in, raw_amount.toString()),
      )

      const route_trade = new RouterTrade({
        v3Routes: [
          {
            // eslint-disable-next-line @cspell/spellchecker
            routev3: route,
            inputAmount: trade.inputAmount,
            outputAmount: trade.outputAmount,
          },
        ],
        tradeType: trade.tradeType,
      })

      const { permitSingle, signature } = await createSingleAsync({
        token_address: token_in_address,
        token_symbol: 'USDC',
        destination_address: universal_router_address,
        token_amount: raw_amount,
      })

      const params = SwapRouter.swapCallParameters(route_trade, {
        slippageTolerance: new Percent(swap_slip_tolerance_percentage, 100),
        inputTokenPermit: {
          ...permitSingle,
          signature,
        },
      })

      const tx = await send_transaction.sendTransactionAsync({
        to: universal_router_address,
        data: params.calldata as Hex,
        value: 0n,
      })
      await client.waitForTransactionReceipt({ hash: tx })
      posthog.capture('swap completed', {
        ...base,
        tokens_in: args.amount.toString(),
      })
    },
    onSuccess: async () => {
      if (wallet_address == null) return
      await queryClient.invalidateQueries({
        queryKey: wallet_keys.balance.wallet(wallet_address).queryKey,
      })
    },
  })
}

// eslint-disable-next-line complexity, max-lines-per-function
export function useGetPositionAmounts({
  fund_symbol,
  price_low,
  price_high,
  deposit_usdc,
  deposit_token,
  in_side,
}: {
  fund_symbol: TokenSymbol
  price_low: undefined | number | ''
  price_high: undefined | number | ''
  deposit_usdc: undefined | number
  deposit_token: undefined | number
  in_side: InSideType
}) {
  const { token_find_by } = useListTokens()
  const fund_token = token_find_by({ symbol: fund_symbol })
  const { data: fund_decimals } = useGetDecimals(fund_token?.token_address)
  const usdc_token = token_find_by({ symbol: 'USDC' })
  const { data: usdc_decimals } = useGetDecimals(usdc_token?.token_address)
  const { data: pool } = useGetPoolConfig(fund_symbol)
  const { balance: balance_token } = useGetWalletBalance(fund_symbol)
  const { balance: balance_usdc } = useGetWalletBalance('USDC')

  const amount = in_side == 'USDC' ? deposit_usdc : deposit_token || 1

  const enabled =
    fund_token != null &&
    fund_decimals != null &&
    usdc_token != null &&
    usdc_decimals != null &&
    pool != null &&
    typeof price_low == 'number' &&
    typeof price_high == 'number' &&
    amount != null &&
    balance_token != null &&
    balance_usdc != null

  if (!enabled) {
    return {
      enabled: false as const,
      price_low: null,
      price_high: null,
      deposit_usdc: null,
      deposit_token: null,
    }
  }

  const fund_u_token = convert_to_u_token(fund_token, fund_decimals)
  const usdc_u_token = convert_to_u_token(usdc_token, usdc_decimals)

  const [token0, token1] = usdc_u_token.sortsBefore(fund_u_token)
    ? [usdc_u_token, fund_u_token]
    : [fund_u_token, usdc_u_token]

  const price_low_ticker = nearestUsableTick(
    priceToClosestTick(
      new Price(
        fund_u_token,
        usdc_u_token,
        parseUnits('1', fund_decimals).toString(),
        parseUnits(price_low.toString(), usdc_decimals).toString(),
      ),
    ),
    pool.tickSpacing,
  )

  const price_high_ticker = nearestUsableTick(
    priceToClosestTick(
      new Price(
        fund_u_token,
        usdc_u_token,
        parseUnits('1', fund_decimals).toString(),
        parseUnits(price_high.toString(), usdc_decimals).toString(),
      ),
    ),
    pool.tickSpacing,
  )

  const [tickLower, tickUpper] =
    price_low_ticker < price_high_ticker
      ? [price_low_ticker, price_high_ticker]
      : [price_high_ticker, price_low_ticker]

  const outside_current = !(
    tickLower <= pool.tickCurrent && pool.tickCurrent <= tickUpper
  )
  if (
    tickLower < TickMath.MIN_TICK ||
    tickUpper > TickMath.MAX_TICK ||
    tickLower == tickUpper
  ) {
    return {
      enabled: false as const,
      price_low: null,
      price_high: null,
      deposit_usdc: null,
      deposit_token: null,
    }
  }
  let amount0: BigintIsh
  let amount1: BigintIsh
  if (in_side == 'USDC') {
    if (token0.symbol == 'USDC') {
      amount0 = parseUnits(amount.toString(), token0.decimals).toString()
      amount1 = parseUnits(balance_token.toString(), token1.decimals).toString()
    } else {
      amount0 = parseUnits(balance_token.toString(), token1.decimals).toString()
      amount1 = parseUnits(amount.toString(), token0.decimals).toString()
    }
  } else {
    if (token0.symbol == 'TOKEN') {
      amount0 = parseUnits(amount.toString(), token0.decimals).toString()
      amount1 = parseUnits(balance_usdc.toString(), token1.decimals).toString()
    } else {
      amount0 = parseUnits(balance_usdc.toString(), token1.decimals).toString()
      amount1 = parseUnits(amount.toString(), token0.decimals).toString()
    }
  }

  const positionToMint = Position.fromAmounts({
    pool,
    tickLower,
    tickUpper,
    amount0,
    amount1,
    useFullPrecision: true,
  })

  const [updated_deposit_usdc, updated_deposit_token] =
    positionToMint.amount0.currency.symbol == 'USDC'
      ? [positionToMint.amount0, positionToMint.amount1]
      : [positionToMint.amount1, positionToMint.amount0]

  return {
    enabled: true as const,
    outside_current,
    price_low: Number(
      tickToPrice(fund_u_token, usdc_u_token, price_low_ticker).quotient,
    ),
    price_high: Number(
      tickToPrice(fund_u_token, usdc_u_token, price_high_ticker).quotient,
    ),
    deposit_usdc: Number(updated_deposit_usdc.toFixed(4)),
    deposit_token: Number(updated_deposit_token.toFixed(4)),
  }
}

// eslint-disable-next-line max-lines-per-function, complexity
export function useCreatePosition({
  fund_symbol,
}: {
  fund_symbol: TokenSymbol
}) {
  const { token_find_by } = useListTokens()
  const fund_token = token_find_by({ symbol: fund_symbol })
  const { data: fund_decimals } = useGetDecimals(fund_token?.token_address)
  const usdc_token = token_find_by({ symbol: 'USDC' })
  const { data: usdc_decimals } = useGetDecimals(usdc_token?.token_address)
  const { data: pool } = useGetPoolConfig(fund_symbol)
  const send_transaction = useSendTransaction()
  const { switchChainAsync } = useSwitchChain()
  const position_manager_address = useGetContractAddress('POSITION_MANAGER')
  const contract = useWriteContract()
  const client = usePublicClient({ chainId: active_chain.id })
  const { address } = useAccount()
  const queryClient = useQueryClient()

  return useMutation({
    // eslint-disable-next-line complexity, max-lines-per-function
    mutationFn: async ({
      price_low,
      price_high,
      deposit_usdc,
      deposit_token,
    }: {
      price_low: undefined | number | ''
      price_high: undefined | number | ''
      deposit_usdc: undefined | number
      deposit_token: undefined | number
    }) => {
      if (
        price_low == null ||
        price_high == null ||
        deposit_usdc == null ||
        deposit_token == null ||
        fund_token == null ||
        usdc_token == null ||
        fund_decimals == null ||
        usdc_decimals == null ||
        pool == null ||
        position_manager_address == null ||
        client == null ||
        address == null
      ) {
        return
      }

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const fund_u_token = CurrencyAmount.fromRawAmount(
        convert_to_u_token(fund_token, fund_decimals),
        parseUnits(deposit_token.toString(), fund_decimals).toString(),
      )
      const usdc_u_token = CurrencyAmount.fromRawAmount(
        convert_to_u_token(usdc_token, usdc_decimals),
        parseUnits(deposit_usdc.toString(), usdc_decimals).toString(),
      )

      const [token0, token1] = usdc_u_token.currency.sortsBefore(
        fund_u_token.currency,
      )
        ? [usdc_u_token, fund_u_token]
        : [fund_u_token, usdc_u_token]

      const price_low_ticker = nearestUsableTick(
        priceToClosestTick(
          new Price(
            fund_u_token.currency,
            usdc_u_token.currency,
            parseUnits('1', fund_decimals).toString(),
            parseUnits(price_low.toString(), usdc_decimals).toString(),
          ),
        ),
        pool.tickSpacing,
      )

      const price_high_ticker = nearestUsableTick(
        priceToClosestTick(
          new Price(
            fund_u_token.currency,
            usdc_u_token.currency,
            parseUnits('1', fund_decimals).toString(),
            parseUnits(price_high.toString(), usdc_decimals).toString(),
          ),
        ),
        pool.tickSpacing,
      )

      const [tickLower, tickUpper] =
        price_low_ticker < price_high_ticker
          ? [price_low_ticker, price_high_ticker]
          : [price_high_ticker, price_low_ticker]

      const positionToMint = Position.fromAmounts({
        pool,
        tickLower,
        tickUpper,
        amount0: token0.quotient,
        amount1: token1.quotient,
        useFullPrecision: true,
      })

      let tx = await contract.writeContractAsync({
        abi: erc20Abi,
        address: token0.currency.address as CryptoAddress,
        functionName: 'approve',
        chainId: active_chain.id,
        args: [position_manager_address, BigInt(token0.quotient.toString())],
      })
      await client.waitForTransactionReceipt({ hash: tx })

      tx = await contract.writeContractAsync({
        abi: erc20Abi,
        address: token1.currency.address as CryptoAddress,
        functionName: 'approve',
        chainId: active_chain.id,
        args: [position_manager_address, BigInt(token1.quotient.toString())],
      })
      await client.waitForTransactionReceipt({ hash: tx })

      const mintOptions: MintOptions = {
        recipient: address,
        deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes
        slippageTolerance: new Percent(5, 100),
      }

      const { calldata } = NonfungiblePositionManager.addCallParameters(
        positionToMint,
        mintOptions,
      )

      tx = await send_transaction.sendTransactionAsync({
        to: position_manager_address,
        data: calldata as Hex,
      })
      await client.waitForTransactionReceipt({ hash: tx })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(keys.dex.lp_positions(address!))
    },
  })
}

export function useClosePosition({
  fund_symbol,
}: {
  fund_symbol: TokenSymbol
}) {
  const { data: pool } = useGetPoolConfig(fund_symbol)
  const send_transaction = useSendTransaction()
  const { switchChainAsync } = useSwitchChain()
  const position_manager_address = useGetContractAddress('POSITION_MANAGER')
  const { address } = useAccount()
  const client = usePublicClient({ chainId: active_chain.id })
  const queryClient = useQueryClient()

  const results = useMutation({
    mutationFn: async ({ position_id }: { position_id: number }) => {
      if (
        position_manager_address == null ||
        client == null ||
        address == null ||
        pool == null
      ) {
        return
      }

      const raw_position = await client.readContract({
        abi: positionManagerAbi,
        functionName: 'positions',
        address: position_manager_address,
        args: [BigInt(position_id)],
      })

      const lp_position = new Position({
        pool,
        liquidity: raw_position[7].toString(),
        tickLower: raw_position[5],
        tickUpper: raw_position[6],
      })

      const { calldata } = NonfungiblePositionManager.removeCallParameters(
        lp_position,
        {
          tokenId: position_id,
          liquidityPercentage: new Percent(1, 1),
          deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes
          slippageTolerance: new Percent(5, 100),
          burnToken: true,
          collectOptions: {
            expectedCurrencyOwed0: lp_position.amount0,
            expectedCurrencyOwed1: lp_position.amount1,
            recipient: address,
          },
        },
      )

      // ensure network is correct
      await switchChainAsync({ chainId: active_chain.id })

      const tx = await send_transaction.sendTransactionAsync({
        to: position_manager_address,
        data: calldata as Hex,
      })
      await client.waitForTransactionReceipt({ hash: tx })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(keys.dex.lp_positions(address!))
    },
  })

  return {
    ...results,
    isReady: !(
      position_manager_address == null ||
      client == null ||
      address == null ||
      pool == null
    ),
  }
}

export type PositionsType = {
  total_usdc_tokens: number
  total_fund_tokens: number
  positions: {
    id: number
    in_range: boolean
    price_low: number
    price_high: number
    usdc_pool_tokens: number
    fund_pool_tokens: number
    usdc_rewards: number
    fund_rewards: number
  }[]
}

// eslint-disable-next-line max-lines-per-function
export function useGetPositions({
  wallet_address,
}: {
  wallet_address: undefined | CryptoAddress
}) {
  const tech_address = useTokenInfo({ symbol: 'TECH' }).data?.token_address
  const usdc_address = useTokenInfo({ symbol: 'USDC' }).data?.token_address
  const { data: tech_decimals } = useGetDecimals(tech_address)
  const { data: usdc_decimals } = useGetDecimals(usdc_address)
  const lp_manager_address = useGetContractAddress('POSITION_MANAGER')
  const client = usePublicClient({ chainId: active_chain.id })
  const { data: pool } = useGetPoolConfig('TECH')

  const enabled =
    client != null &&
    wallet_address != null &&
    tech_address != null &&
    tech_decimals != null &&
    usdc_address != null &&
    usdc_decimals != null &&
    lp_manager_address != null &&
    pool != null

  return useQuery<PositionsType>({
    queryKey: !enabled ? [] : keys.dex.lp_positions(wallet_address).queryKey,
    queryFn: !enabled
      ? skipToken
      : // eslint-disable-next-line max-lines-per-function
        async () => {
          const count = await client.readContract({
            abi: positionManagerAbi,
            functionName: 'balanceOf',
            address: lp_manager_address,
            args: [wallet_address],
          })

          const token_ids = await client.multicall({
            contracts: _.times(Number(count), (i) => ({
              abi: positionManagerAbi,
              functionName: 'tokenOfOwnerByIndex' as const,
              address: lp_manager_address,
              args: [wallet_address, i],
            })),
          })

          const lp_ids = token_ids
            .map(({ result }) => result)
            .filter((r): r is bigint => r != null)

          let raw_positions = (
            await client.multicall({
              contracts: lp_ids.map((lp_id) => ({
                abi: positionManagerAbi,
                functionName: 'positions' as const,
                address: lp_manager_address,
                args: [lp_id],
              })),
            })
          ).map((p, index) => ({ ...p, id: Number(lp_ids[index]) }))

          raw_positions = raw_positions.filter((p) => {
            if (p.result == null) return false
            const tokens = [
              p.result[2].toLowerCase(),
              p.result[3].toLowerCase(),
            ]
            const liquidity = p.result[7] ?? 0
            return (
              tokens.includes(usdc_address.toLowerCase()) &&
              tokens.includes(tech_address.toLowerCase()) &&
              liquidity > 0
            )
          })

          const positions = raw_positions.map((p) => {
            const lp_position = new Position({
              pool,
              liquidity: p.result![7].toString(),
              tickLower: p.result![5],
              tickUpper: p.result![6],
            })
            const in_range =
              lp_position.tickLower <= pool.tickCurrent &&
              pool.tickCurrent <= lp_position.tickUpper
            const tickRange = [lp_position.tickLower, lp_position.tickUpper]

            const usdc_token = findCurrencyAmount({
              symbol: 'USDC',
              pair: [lp_position.amount0, lp_position.amount1],
            })
            const fund_token = findCurrencyAmount({
              symbol: 'TECH',
              pair: [lp_position.amount0, lp_position.amount1],
            })

            const tick_prices = [
              Number(
                tickToPrice(
                  fund_token.currency,
                  usdc_token.currency,
                  tickRange[0],
                ).quotient,
              ),
              Number(
                tickToPrice(
                  fund_token.currency,
                  usdc_token.currency,
                  tickRange[1],
                ).quotient,
              ),
            ]
              .sort()
              .reverse()

            return {
              id: p.id,
              in_range,
              price_low: tick_prices[0],
              price_high: tick_prices[1],
              usdc_amount: BigInt(usdc_token.quotient.toString()),
              fund_amount: BigInt(fund_token.quotient.toString()),
              usdc_amount_rewarded:
                pool.token0.symbol == 'USDC' ? p.result![10] : p.result![11],
              fund_amount_rewarded:
                pool.token0.symbol == 'USDC' ? p.result![11] : p.result![10],
            }
          })

          const usdc_amount_owned = positions.reduce(
            (acc, c) => acc + c.usdc_amount + c.usdc_amount_rewarded,
            0n,
          )
          const fund_amount_owned = positions.reduce(
            (acc, c) => acc + c.fund_amount + c.fund_amount_rewarded,
            0n,
          )

          return {
            total_usdc_tokens: amount_to_tokens(
              usdc_amount_owned,
              usdc_decimals,
            ),
            total_fund_tokens: amount_to_tokens(
              fund_amount_owned,
              tech_decimals,
            ),
            positions: positions.map((p) => ({
              id: p.id,
              in_range: p.in_range,
              price_low: p.price_low,
              price_high: p.price_high,
              usdc_pool_tokens: amount_to_tokens(p.usdc_amount, usdc_decimals),
              fund_pool_tokens: amount_to_tokens(p.fund_amount, tech_decimals),
              usdc_rewards: amount_to_tokens(
                p.usdc_amount_rewarded,
                usdc_decimals,
              ),
              fund_rewards: amount_to_tokens(
                p.fund_amount_rewarded,
                tech_decimals,
              ),
            })),
          }
        },
  })
}
