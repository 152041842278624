/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { memo, useMemo } from 'react'

import { Group, Stack, Text } from '@mantine/core'
import * as _ from 'lodash-es'

import {
  TokenImage,
  type TokenImageSlug,
} from '@repo/common/components/TokenImage'
import { usdLargeNumberFormatter } from '@repo/common/helpers/formatters'
import { useFundInfo } from '@repo/common/queries/funds'

import { section_ordering } from '@/data/companies'
import type { SectionsKeys } from '@/data/companies/text_sections'
import {
  useCompanyMetrics,
  useCompanyRounds,
  useGetCompany,
} from '@/queries/company'

import { CompanyLogo } from '../CompanyLogo'
import { Link } from '../Link'

import { getBlockComponent } from './ResearchBlock'
import { RoundsBlock } from './RoundsBlock'
import { ScrollToBlock, TextSectionBlock } from './SectionBlock'
import { StatBox } from './StatBox'
import { useScrollSync } from './useScrollSync'

import stat_box_classes from './StatBox.module.css'

const number_options = {
  missing_make_blank: true,
  maximumFractionDigits: 1,
  missing_string: 'n/a',
}

export const ResearchCompanyPage = memo<{
  symbol: string
  cms_name: string
  section?: string
}>(function ResearchCompanyPage(params) {
  const { symbol, cms_name } = params
  const { onScrollFinish, onInViewport, scroll_to } = useScrollSync(params)

  const company_text = useGetCompany({ cms_name })
  const metrics_data = useCompanyMetrics()
  const rounds_data = useCompanyRounds()
  const fund = useFundInfo({ symbol })

  const company = company_text.data
  const metrics = metrics_data.data?.[cms_name]
  const rounds = rounds_data.data?.[cms_name]

  const stats = useMemo(
    () =>
      [
        { name: 'Name', value: company?.name ?? '' },
        {
          name: 'Industry',
          section: 'industry',
          value: metrics?.industry ?? 'n/a',
        },
        {
          name: 'Revenue',
          section: 'revenue',
          value: usdLargeNumberFormatter(
            _.last(metrics?.revenue ?? [])?.amount,
            number_options,
          ),
        },
        {
          name: 'Valuation',
          section: 'funding rounds',
          value: usdLargeNumberFormatter(
            _.sortBy(
              (rounds ?? []).filter(
                ({ post_money_valuation }) => post_money_valuation != null,
              ),
              'date',
            )[0]?.post_money_valuation,
            number_options,
          ),
        },
        {
          name: 'TAM',
          section: 'key competitors',
          value: usdLargeNumberFormatter(
            _.last(metrics?.tam ?? [])?.value,
            number_options,
          ),
        },
      ] satisfies { name: string; value: string; section?: SectionsKeys }[],
    [company?.name, metrics?.industry, metrics?.revenue, metrics?.tam, rounds],
  )

  const isLoading = [company_text, metrics_data, rounds_data, fund].some(
    (data) => data.isLoading,
  )
  const has_data = [company, metrics, rounds, fund].every(
    (data) => data != null,
  )

  if (isLoading) {
    return <Text>Loading...</Text>
  }

  if (!has_data) {
    return <Text>No data</Text>
  }

  return (
    <Stack>
      <ScrollToBlock
        title="overview"
        onInViewport={onInViewport}
        scroll_to={scroll_to == 'overview'}
        onScrollFinish={onScrollFinish}
      >
        <Group gap="md">
          <Link
            to="/investments/$symbol"
            params={{ symbol }}
            className={stat_box_classes.link}
          >
            <Group gap="md">
              <TokenImage
                image_slug={(fund.data?.name ?? '') as TokenImageSlug}
                size="50"
              />
              <Stack gap="0">
                <Text size="lg" fw="bold" lh="1">
                  {fund.data?.name}
                </Text>
                <Text size="sm">{symbol}</Text>
              </Stack>
            </Group>
          </Link>
          <CompanyLogo
            cms_name={cms_name}
            h="4rem"
            variant="white"
            title={company?.name ?? cms_name}
          />
        </Group>
      </ScrollToBlock>
      <Group gap="md">
        {stats.map(({ name, value, section }) => (
          <StatBox
            key={name}
            name={name}
            value={value}
            params={params}
            section={section}
          />
        ))}
      </Group>
      <Stack gap="xl">
        {section_ordering.map((title) => {
          if (title === 'funding rounds') {
            if (rounds == null) return null
            return (
              <RoundsBlock
                cms_name={cms_name}
                key={title}
                rounds={rounds}
                title={title}
                onInViewport={onInViewport}
                scroll_to={scroll_to == _.kebabCase(title)}
                onScrollFinish={onScrollFinish}
              />
            )
          }
          return (
            <TextSectionBlock
              key={title}
              title={title}
              lines={company?.[title] ?? []}
              onInViewport={onInViewport}
              scroll_to={
                scroll_to !== 'overview' && scroll_to == _.kebabCase(title)
              }
              onScrollFinish={onScrollFinish}
            >
              {getBlockComponent({
                section: title,
                company: company!,
                metrics: metrics!,
              })}
            </TextSectionBlock>
          )
        })}
      </Stack>
    </Stack>
  )
})
