import { memo, useEffect } from 'react'

import {
  Box,
  Button,
  Grid,
  Group,
  NumberInput,
  Slider,
  Stack,
  Text,
} from '@mantine/core'
import { useForm, type UseFormReturnType, zodResolver } from '@mantine/form'
import { useFocusWithin } from '@mantine/hooks'
import * as _ from 'lodash-es'
import { useAccount } from 'wagmi'
import { z } from 'zod'

import { ConnectWalletButton } from '@repo/common/components/ConnectWalletButton'
import {
  symbol_to_render_props,
  type TokenImageSlug,
  TokenRender,
  type TokenRenderProps,
} from '@repo/common/components/TokenRender'
import { WalletExecuteButton } from '@repo/common/components/WalletExecuteButton'
import { countFormatter, usdFormatter } from '@repo/common/helpers/formatters'
import { useBuyCartVault } from '@repo/common/queries/cart'
import { useGetTokenPrice } from '@repo/common/queries/fission_dex'
import type { TokenSymbol } from '@repo/common/queries/products'
import {
  useGetDepositFromDepositValue,
  useGetVaultLatestData,
  useMyGetMaxDepositValues,
} from '@repo/common/queries/vaults'

// eslint-disable-next-line max-lines-per-function
import classes from './PanelBuyVault.module.css'

const default_reason = 'Enter deposit value'

export const PanelBuyVault = memo<{
  vault_id: RubyID
  onCancel: () => void
  onCompleted: () => void
  amount?: number
}>(
  // eslint-disable-next-line max-lines-per-function, complexity
  function PanelBuyVault({ vault_id, onCancel, onCompleted }) {
    const { isConnected } = useAccount()
    const vault_price_result = useGetVaultLatestData({ id: vault_id })
    const fund_symbol: TokenSymbol = 'TECH'
    const { data: tech_price } = useGetTokenPrice({ symbol: fund_symbol })
    const cart = useBuyCartVault()

    const form = useForm({
      initialValues: {
        percentage_of_total: 50,
        deposit_total_usdc_value: 0,
        deposit_max_usdc_value: 0,
      } as {
        percentage_of_total: number
        deposit_total_usdc_value: number
        deposit_max_usdc_value: number
      },
      validateInputOnChange: true,
      validate: (values) => zodResolver(get_schema(values))(values),
    })

    const deposit_total_focus = useFocusWithin()

    const maxes = useMyGetMaxDepositValues({
      vault_id,
    })

    const deposits = useGetDepositFromDepositValue({
      vault_id,
      deposit_total_usdc_value: form.getValues().deposit_total_usdc_value,
    })

    const get_schema = ({
      deposit_max_usdc_value,
    }: {
      deposit_max_usdc_value: number
    }) => {
      return z.object({
        deposit_total_usdc_value: z.coerce
          .number()
          .positive(default_reason)
          .max(deposit_max_usdc_value, {
            message: `Insufficient balances`,
          }),
      })
    }

    useEffect(() => {
      if (tech_price == null || !maxes.enabled) return
      const deposit_max_usdc_value =
        tech_price * maxes.max_deposit_fund_tokens +
        maxes.max_deposit_usdc_tokens
      form.setValues({
        deposit_max_usdc_value,
        deposit_total_usdc_value: _.floor(
          (deposit_max_usdc_value * form.getValues().percentage_of_total) / 100,
          2,
        ),
      })
      // form changes every time
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      maxes.enabled,
      maxes.max_deposit_fund_tokens,
      maxes.max_deposit_usdc_tokens,
      tech_price,
    ])

    form.watch('deposit_total_usdc_value', () => {
      if (!deposit_total_focus.focused) return
      form.setValues({
        percentage_of_total:
          (form.getValues().deposit_total_usdc_value /
            form.getValues().deposit_max_usdc_value) *
          100,
      })
    })

    form.watch('percentage_of_total', () => {
      if (deposit_total_focus.focused) return
      form.setValues({
        deposit_total_usdc_value: _.floor(
          (form.getValues().percentage_of_total / 100) *
            form.getValues().deposit_max_usdc_value,
          2,
        ),
      })
    })

    const onSubmit = (
      values: Parameters<Parameters<typeof form.onSubmit>[0]>[0],
    ) => {
      cart.setValues({
        ...deposits.re_compute(values.deposit_total_usdc_value),
        id: vault_id,
      })
      onCompleted()
    }

    const enabled = form.isValid()
    const reason =
      get_schema(form.getValues()).safeParse(form.getValues()).error?.errors[0]
        ?.message || default_reason

    const vault_price = Number(vault_price_result.data?.price_per_token)

    if (vault_price == null || tech_price == null) {
      return null
    }

    let button_content
    if (isConnected == false) {
      button_content = (
        <ConnectWalletButton
          size="lg"
          miw="14em"
          message="Connect Wallet First"
        />
      )
    } else {
      button_content = (
        <WalletExecuteButton
          disabled={!enabled}
          variant={enabled ? 'filled' : 'outline'}
          size="lg"
          miw="14em"
          type="submit"
        >
          {enabled ? 'Buy' : reason}
        </WalletExecuteButton>
      )
    }

    const deposit_max_usdc_value = form.getValues().deposit_max_usdc_value

    return (
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Grid columns={4} gutter="0" className={classes.root}>
          <Grid.Col span={3} className={classes.row}>
            <Group>
              <TokenRender
                image_slug="technology"
                type="vault"
                size="var(--mantine-font-size-xl2)"
              />
              <Stack gap="0">
                <Text size="lg" lh="1">
                  TECH Vault
                </Text>
                <Text size="sm">TVLT</Text>
              </Stack>
            </Group>
          </Grid.Col>
          <Grid.Col span={1} className={classes.row_value}>
            <Text>{usdFormatter(vault_price)}/share</Text>
          </Grid.Col>
          <Grid.Col span={2} className={classes.row}>
            <Text size="sm">Order Type</Text>
          </Grid.Col>
          <Grid.Col span={2} className={classes.row_value}>
            <Text>Market</Text>
          </Grid.Col>
          <Grid.Col offset={0} span={4} className={classes.row_value}>
            <Text size="sm" opacity={0.7}>
              The Vault optimizes the deposit ratio in order to maximize returns
              on its liquidity position given its current undeployed asset
              balances.
            </Text>
          </Grid.Col>
          <Grid.Col span={2} className={classes.row}>
            <Text size="sm">Deposit total $</Text>
          </Grid.Col>
          <Grid.Col span={2} className={classes.row_value}>
            <NumberInput
              ref={deposit_total_focus.ref}
              min={0}
              max={form.getValues().deposit_max_usdc_value}
              decimalScale={2}
              fixedDecimalScale
              size="lg"
              prefix="$"
              name="deposit_total_value"
              id="deposit_total_value"
              aria-label="deposit total value"
              {...form.getInputProps('deposit_total_usdc_value')}
            />
          </Grid.Col>
          <Grid.Col span={4} className={classes.row}>
            <Stack w="100%" align="flex-end" gap="0">
              <Box
                w="100%"
                pt="calc(0.5 * var(--mantine-font-size-lg))"
                pb="calc(1 * var(--mantine-font-size-lg))"
                mb="2px"
                px="calc(0.5 * var(--mantine-font-size-lg))"
              >
                <Slider
                  w="100%"
                  defaultValue={50}
                  {...form.getInputProps('percentage_of_total')}
                  marks={[
                    return_mark({
                      percentage: 25,
                      deposit_max_usdc_value,
                      form,
                    }),
                    return_mark({
                      percentage: 50,
                      deposit_max_usdc_value,
                      form,
                    }),
                    return_mark({
                      percentage: 75,
                      deposit_max_usdc_value,
                      form,
                    }),
                    return_mark({
                      percentage: 100,
                      deposit_max_usdc_value,
                      form,
                    }),
                  ]}
                  label={null}
                  classNames={{
                    thumb: classes.slider_thumb,
                    markLabel: classes.slider_mark_label,
                  }}
                  thumbSize="var(--mantine-font-size-lg)"
                />
              </Box>
            </Stack>
          </Grid.Col>
          <DepositRow
            symbol="USDC"
            deposit_tokens={deposits.deposit_usdc_tokens}
            token_price={1}
            balance_tokens={maxes.balance_usdc_tokens}
            image_slug="usdc"
          />
          <DepositRow
            symbol={fund_symbol}
            deposit_tokens={deposits.deposit_fund_tokens}
            token_price={tech_price}
            balance_tokens={maxes.balance_fund_tokens}
            image_slug="technology"
          />
          <Grid.Col span={2} fw="bold" className={classes.row}>
            <Text size="md">Total TVLT</Text>
          </Grid.Col>
          <Grid.Col span={2} data-testid="total" className={classes.row_value}>
            <TokenAndValueBlock
              image_slug="technology"
              image_type="vault"
              symbol="TVLT"
              tokens={form.getValues().deposit_total_usdc_value / vault_price}
              token_price={vault_price}
            />
          </Grid.Col>
          <Grid.Col span={4} mt="lg">
            <Group justify="flex-end">
              <Button variant="subtle" onClick={onCancel}>
                Cancel
              </Button>
              {button_content}
            </Group>
          </Grid.Col>
        </Grid>
      </form>
    )
  },
)

function DepositRow({
  symbol,
  balance_tokens,
  deposit_tokens,
  token_price,
  image_slug,
}: {
  symbol: TokenSymbol
  deposit_tokens?: number
  token_price: number
  balance_tokens: number
  image_slug: TokenImageSlug
}) {
  if (deposit_tokens == null) return null
  return (
    <>
      <Grid.Col span={2} className={classes.row}>
        <Stack gap="0" opacity={0.7}>
          <Text size="md">Deposit {symbol}</Text>

          <Group gap="0.2em">
            <Text size="sm">Balance: </Text>
            <TokenRender
              {...symbol_to_render_props[symbol]}
              size="var(--mantine-font-size-md)"
            />
            <Text size="sm" data-testid={`wallet ${symbol} balance`}>
              {countFormatter(balance_tokens, {
                maximumFractionDigits: 4,
                compact: false,
              })}
            </Text>
          </Group>
        </Stack>
      </Grid.Col>
      <Grid.Col span={2} className={classes.row_value}>
        <TokenAndValueBlock
          tokens={deposit_tokens}
          token_price={token_price}
          image_slug={image_slug}
          symbol={symbol}
        />
      </Grid.Col>
    </>
  )
}

function TokenAndValueBlock({
  tokens,
  symbol,
  token_price,
  image_type = 'token',
  image_slug,
}: {
  tokens: number
  symbol: TokenSymbol
  image_type?: TokenRenderProps['type']
  token_price: number
  image_slug: TokenImageSlug
}) {
  return (
    <Stack gap="0" align="flex-end">
      <Group justify="flex-end" gap="0.5ex">
        <TokenRender
          type={image_type}
          size="var(--mantine-font-size-lg)"
          image_slug={image_slug}
        />
        <Text size="lg" data-testid={`${symbol} total`}>
          {countFormatter(tokens, {
            maximumFractionDigits: 4,
            compact: false,
          })}
        </Text>
      </Group>
      <Text size="sm" opacity={0.7} data-testid={`${symbol} $`}>
        {usdFormatter(token_price * tokens, true)}
      </Text>
    </Stack>
  )
}

function return_mark<V extends { percentage_of_total: number }>({
  percentage,
  deposit_max_usdc_value,
  form,
}: {
  percentage: number
  deposit_max_usdc_value: number
  form: UseFormReturnType<V>
}) {
  return {
    value: percentage,
    label: (
      <Button
        size="compact-sm"
        variant="subtle"
        // odd TS issue
        onClick={() =>
          form.setValues({ percentage_of_total: percentage } as Partial<V>)
        }
      >
        {percentage == 100
          ? 'MAX'
          : usdFormatter((percentage / 100) * deposit_max_usdc_value, false)}
      </Button>
    ),
  }
}
